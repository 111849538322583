import React, { useCallback, useContext, useEffect, useState } from "react";

import { Stack } from "@mui/material";
import { DateTime } from "luxon";
import { useConfirm } from "material-ui-confirm";
import { useForm } from "react-hook-form";
import { useBlocker, useNavigate } from "react-router";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import InputDate from "@shared/forms/inputs/InputDate";
import InputNumberField from "@shared/forms/inputs/InputNumberField";
import InputRadioGroup from "@shared/forms/inputs/InputRadioGroup";
import InputRichTextArea from "@shared/forms/inputs/InputRichTextArea";
import InputSelect from "@shared/forms/inputs/InputSelect";
import InputSelectMulti from "@shared/forms/inputs/InputSelectMulti";
import InputTextField from "@shared/forms/inputs/InputTextField";
import InputTime from "@shared/forms/inputs/InputTime";
import { renderButton, renderSubmitButton, renderCancelButton } from "@shared/FormUtils";
import { useAddSession, useUpdateSession } from "@shared/hooks/useSessions";
import PageHeader from "@shared/PageHeader";

const SessionForm = (props) => {
  const { config, session, tags, tracks, types, venues } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const [allowAnyDate, setAllowAnyDate] = useState(false);
  const [blockerActive, setBlockerActive] = useState(true);
  const addSession = useAddSession(apiRoot, event.id);
  const updateSession = useUpdateSession(apiRoot, event.id);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      blockerActive &&
      isDirty &&
      currentLocation.pathname !== nextLocation.pathname
  );

  const showConfirm = useCallback(() => {
    confirm({
      title: "Unsaved Changes",
      description: "You have unsaved changes. Are you sure you want to navigate away?"
    })
      .then(() => {
        blocker.proceed();
      })
      .catch(() => {
        blocker.reset();
      });
  }, [blocker, confirm]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      showConfirm();
    }
  }, [blocker.state, showConfirm]);

  const rowClasses = "mb-1 flex justify-start gap-4";

  // the datetime picker uses JS Date objects which makes timezones a royal pain.
  // Here we are making a Date object that uses the "local" time but transposed
  // into the user's timezone. This is OK because the form submit ignores the timezone
  // This is all to make the datetime picker cooperate.
  const dateInitialValue = () => {
    if (session.date_and_time_local) {
      const dateWithoutZone = DateTime.fromISO(session.date_and_time_local.split(".")[0]).toJSDate();
      return dateWithoutZone;
    }
    if (event.date_begin) {
      return DateTime.fromISO(event.date_begin).toJSDate();
    }
    return new Date();
  };

  const timeInitialValue = () => {
    if (session.date_and_time_local) {
      const dateWithoutZone = DateTime.fromISO(session.date_and_time_local.split(".")[0]).toJSDate();
      return dateWithoutZone;
    }
    return DateTime.fromFormat("08:00 AM", "hh:mm a").toJSDate();
  };

  const venueInitialValue = () => {
    if (!session.session_room_id) {
      return "";
    };

    const selectedVenues = venues.filter((venue) => venue.rooms.find((room) => room.id === session.session_room_id));
    return selectedVenues[0]?.id;
  };

  const tagsInitialValue = () => {
    if (!session) {
      return [];
    };

    return session?.session_tags?.map((tagName) => tags.find(t => t.name === tagName).id) || [];
  };

  const tagSelectInitialValue = () => {
    if (!session) {
      return [];
    };

    return tags.filter(t => session?.session_tags?.includes(t.name)).map(t => t.id) || [];
  }

  const formDefaultValues = {
    capacity: session.capacity || 0,
    capacity_limited: session.capacity_limited || false,
    code: session.code || "",
    date: dateInitialValue(),
    description: session.description || "",
    enrollment: session.enrollment || "open_enroll",
    length_minutes: session.length_minutes || 60,
    meeting_url: session.meeting_url || "",
    on_demand: session.on_demand || false,
    on_demand_end_date_time: session.on_demand_end_date_time || null,
    presentation_type: session.presentation_type || event.event_type,
    published: session.published || false,
    session_type_id: session.session_type_id || "",
    session_room_id: session.session_room_id || "",
    session_track_id: session.session_track_id || "",
    tags: tagsInitialValue(),
    time: timeInitialValue(),
    title: session.title || "",
    venue_id: venueInitialValue()
  };

  const {
    control,
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
    setValue,
    watch
  } = useForm({
    defaultValues: formDefaultValues
  });

  // watch fields to trigger conditional re-renders
  const watchTitle = watch("title");
  const watchFormat = watch("presentation_type");
  const watchOnDemand = watch("on_demand");
  const watchVenue = watch("venue_id");
  const watchCapacityLimited = watch("capacity_limited");

  const submitFn = (data) => {
    // using blockerActive flag instead of referencing isSubmitting because isSubmitting resets too early
    // for this use case, causing the blocker to re-engage before navigation.
    setBlockerActive(false);

    const callbacks = {
      onSuccess: (data) => {
        if (data.error === null) {
          alertSuccess("Session saved");
          navigate("/sessions");
        } else {
          alertError(`Unable to save session: ${data.error}`);
          setBlockerActive(true);
        }
      },
      onError: (error) => {
        if (error?.response?.data?.error) {
          alertError(error.response?.data?.error);
          setBlockerActive(true);
        } else {
          alertHttpError(error);
          setBlockerActive(true);
        }
      }
    };

    // combine date and time values and deal with timezone
    // This was obnoxious to do in date-fns and it took me like 1 minute to do it in Luxon.
    // If I could go back in time once, I would stop Hitler.
    // If I could go back in time twice, I would buy a load of Apple stock.
    // If I could go back in time three times, I would stop myself from ever polluting a project with date-fns
    // Leave this comment in the code as an eternal testament to how much I hate motherfucking date-fns.
    // I don't care who reads it. Tell Cersei, I want her to know it was me.
    const combinedDateTime = DateTime.fromJSDate(data.date)
                                     .set({ hour: data.time.getHours(), minute: data.time.getMinutes() })
                                     .setZone(event.time_zone, { keepLocalTime: true });
    data.date_and_time = combinedDateTime.toJSDate();

    if (session.id) {
      updateSession.mutate({ id: session.id, data }, callbacks);
    } else {
      addSession.mutate({ data }, callbacks);
    }
  };

  const handleSaveAndPublish = () => {
    setValue("published", true);
    handleSubmit(submitFn)();
  };

  const handleUnpublish = () => {
    setValue("published", false);
    handleSubmit(submitFn)();
  };

  const renderAllowAnyDateToggle = () => {
    const text = allowAnyDate ? "Restrict date to event dates" : "Select date outside of event dates";
    return (
      <div
        className="sg-mgmt-link -mt-1 cursor-pointer text-xs"
        onClick={() => {
          setAllowAnyDate(!allowAnyDate);
        }}
      >
        {text}
      </div>
    );
  };

  const isSavable = () => {
    return !isSubmitting && Object.keys(errors).length === 0 && watchTitle && watchTitle.length > 0;
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" spacing={2}>
        {renderSubmitButton("Save", !isSavable(), { color: session.published ? "completed" : "primary"})}
        {!session.published && (renderButton(`${isDirty ? "Save and " : ""}Publish`, handleSaveAndPublish, { color: "completed", disabled: !isSavable() }))}
        {session.published && (renderButton(`${isDirty ? "Save and " : ""}Unpublish`, handleUnpublish, { color: "primary", disabled: !isSavable() }))}
        {renderCancelButton("Cancel", () => navigate("/sessions"))}
      </Stack>
    );
  };


  return (
    <div className="max-w-screen-lg">
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="my-6">{renderButtons()}</div>
        <div className="mb-1">
          <PageHeader text="Session Content" subHeader />
        </div>
        <div className={rowClasses}>
          <div className="w-2/3 flex-auto">
            <InputTextField
              control={control}
              errors={errors.title}
              label="Session Title"
              optional={false}
              name="title"
            />
          </div>
          {config.enabled_code && (
            <div className="w-1/3 flex-auto">
              <InputTextField control={control} errors={errors.code} label="Code" optional={true} name="code" />
            </div>
          )}
        </div>
        <div className={rowClasses}>
          <div className="w-1/3 flex-initial">
            <InputSelect
              control={control}
              label="Session Track"
              name="session_track_id"
              options={tracks.map((track) => ({ value: track.id, label: track.name }))}
              errors={errors.session_track_id}
            />
          </div>
          <div className="w-1/3 flex-initial">
            <InputSelect
              control={control}
              label="Session Type"
              name="session_type_id"
              options={types.map((type) => ({ value: type.id, label: type.name }))}
              errors={errors.session_type_id}
            />
          </div>
          <div className="w-1/3 flex-initial">
            <InputSelectMulti
              control={control}
              defaultValue={tagSelectInitialValue()}
              label="Session Tags"
              name="tags"
              placeholder="Tag"
              options={tags.map((tag) => ({ value: tag.id, label: tag.name }))}
            />
          </div>
        </div>
        <div className={rowClasses}>
          <div className="w-full flex-initial">
            <InputRichTextArea control={control} errors={errors.description} label="Description" name="description" />
          </div>
        </div>
        <div className="mb-1">
          <PageHeader text="Session Format" subHeader />
        </div>
        <div className={rowClasses}>
          <div className="w-full flex-initial">
            <InputRadioGroup
              control={control}
              errors={errors.presentation_type}
              name="presentation_type"
              options={[
                { value: "physical", label: "Physical (in-person)" },
                { value: "virtual", label: "Virtual (online)" },
                { value: "hybrid", label: "Hybrid (in-person and online)" }
              ]}
              row={true}
            />
          </div>
        </div>
        {watchFormat !== "physical" && (
          <div>
            <div className="mb-1">
              <PageHeader text="Session Delivery" subHeader />
            </div>
            <div className={rowClasses}>
              <div className="w-full flex-initial">
                <InputRadioGroup
                  control={control}
                  errors={errors.on_demand}
                  name="on_demand"
                  options={[
                    { value: false, label: "Scheduled" },
                    { value: true, label: "On-Demand" }
                  ]}
                  row={true}
                />
              </div>
            </div>
          </div>
        )}
        {watchOnDemand !== "true" && (
          <div className="mb-3">
            <div className="mb-1">
              <PageHeader text="Date and Time" subHeader />
            </div>
            <div className={rowClasses}>
              <div className="w-1/3 flex-initial">
                <InputDate
                  control={control}
                  errors={errors.date}
                  label="Date"
                  minDate={allowAnyDate ? null : event.date_begin}
                  maxDate={allowAnyDate ? null : event.date_end}
                  name="date"
                />
                {renderAllowAnyDateToggle()}
              </div>
              <div className="w-1/3 flex-initial">
                <InputTime control={control} errors={errors.time} label="Time" name="time" />
              </div>
              <div className="w-1/3 flex-initial">
                <InputNumberField
                  control={control}
                  errors={errors.length_minutes}
                  label="Length in minutes"
                  name="length_minutes"
                  min={0}
                  max={1440}
                />
              </div>
            </div>
          </div>
        )}
        {watchFormat !== "virtual" && watchOnDemand !== "true" && (
          <div className="mb-1">
            <div className="mb-1">
              <PageHeader text="Location" subHeader />
            </div>
            <div className={rowClasses}>
              <div className="w-1/2 flex-initial">
                <InputSelect
                  control={control}
                  label="Venue"
                  name="venue_id"
                  options={venues.map((venue) => ({ value: venue.id, label: venue.name }))}
                  errors={errors.venue_id}
                />
              </div>
              <div className="w-1/2 flex-initial">
                <InputSelect
                  control={control}
                  label="Room"
                  name="session_room_id"
                  options={venues
                    .find((venue) => venue.id === watchVenue)
                    ?.rooms?.map((room) => ({ value: room.id, label: room.name }))}
                  errors={errors.session_room_id}
                />
              </div>
            </div>
          </div>
        )}
        {watchFormat !== "physical" && (
          <div>
            <div className="mb-1">
              <PageHeader text="Session Meeting URL" subHeader />
            </div>
            <div className="mb-1">{"Enter your virtual session meeting URL here. (i.e. Zoom, Webex)"}</div>
            <div className={rowClasses}>
              <div className="w-full flex-initial">
                <InputTextField
                  control={control}
                  errors={errors.meeting_url}
                  label=""
                  optional
                  name="meeting_url"
                  placeholder="Enter meeting URL"
                />
              </div>
            </div>
          </div>
        )}
        <div>
          <div className="mb-1">
            <PageHeader text="Enrollment" subHeader />
          </div>
          <div className="mb-1">
            {
              "Select 'Open' if you want everyone who is a participant of this event to have access to this session. Select 'Restricted' if you want to limit attendance by custom assigning attendees (ie. by invite only events)."
            }
          </div>
          <div className={rowClasses}>
            <div className="w-full flex-initial">
              <InputRadioGroup
                control={control}
                errors={errors.enrollment}
                name="enrollment"
                options={[
                  { value: "open_enroll", label: "Open" },
                  { value: "closed_enroll", label: "Restricted" }
                ]}
                row={true}
              />
            </div>
          </div>
          <div>
            <div className="mb-1">
              <PageHeader text="Session Capacity" subHeader />
            </div>
            <div className="mb-1">{"Select how many people can attend the session."}</div>
            <div className={rowClasses}>
              <div className="flex-initial">
                <InputRadioGroup
                  control={control}
                  errors={errors.capacity_limited}
                  name="capacity_limited"
                  options={[
                    { value: false, label: "No Limit" },
                    { value: true, label: "Limited" }
                  ]}
                  row={true}
                />
              </div>
              {(watchCapacityLimited === "true" || watchCapacityLimited === true) && (
                <>
                  <div className="w-32 flex-initial pl-2 pt-2">Enter Capacity:</div>
                  <div className="w-24 flex-initial">
                    <InputNumberField
                      control={control}
                      errors={errors.capacity}
                      label=""
                      name="capacity"
                      min={0}
                      max={50000}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="mt-2 mb-4">{renderButtons()}</div>
        </div>
      </form>
    </div>
  );
};

export default SessionForm;
