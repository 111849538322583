import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import SessionsSetting from "@sessions/SessionsSetting";
import { useAddSessionTag, useDeleteSessionTag } from "@shared/hooks/useSessions";

const SessionTags = (props) => {
  const { tags } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const deleteSessionTag = useDeleteSessionTag(apiRoot, event.id);
  const addSessionTag = useAddSessionTag(apiRoot, event.id);

  return (
    <SessionsSetting
      addMutation={addSessionTag}
      deleteMutation={deleteSessionTag}
      collection={tags}
      label="Session Tags"
      name="tag"
      description={
        <div>
          Tags allow you to split up smaller groups that may overlap. A participant can have multiple or no tags.
          <br />
          Examples: Tag by region (EMEA, AMER, APJC), tag by invitation wave (Wave 1, Wave 2 etc.)
        </div>
      }
    />
  );
};

export default SessionTags;
