import React, { useContext } from "react";

import SessionContext from "@sessions/session/SessionContext";
import SessionForm from "@sessions/session/SessionForm";
import SessionTitleAndStatus from "@sessions/session/SessionTitleAndStatus";
import SessionsSettingsContext from "@sessions/SessionsSettingsContext";

const SessionDetails = () => {
  const { config, tags, tracks, types, venues } = useContext(SessionsSettingsContext);
  const { session } = useContext(SessionContext);

  console.log("SessionDetails title: ", session.title);

  return (
    <div>
      <SessionTitleAndStatus />
      <div>
        <div className="my-2">
          {
            "Edit and manage your session content here. Publish the session if it is ready for use, or save it as a draft to come back later."
          }
        </div>
        <SessionForm
          config={config}
          session={session}
          tags={tags}
          types={types}
          tracks={tracks}
          venues={venues}
          updatedAt={session.updated_at}
        />
      </div>
    </div>
  );
};

export default SessionDetails;
