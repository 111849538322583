import React, { useState } from "react";

import { Checkbox, FormControl, FormLabel, FormHelperText, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Controller } from "react-hook-form";

import InputLabel from "@shared/forms/inputs/InputLabel";
import MuiSelectClearable from "@shared/MuiSelectClearable";

const InputSelectMulti = (props) => {
  const { control, defaultValue = [], errors, label, options = [], optional = true, placeholder = null, name } = props;
  const [selected, setSelected] = useState(defaultValue);

  const handleChange = (event, onChange) => {
    setSelected(event.target.value);
    onChange(event.target.value);
  };

  const handleClear = (onChange) => {
    setSelected([]);
    onChange([]);
  };

  return (
    <div className="mb-4 w-full">
      <FormControl fullWidth>
        <FormLabel>
          <InputLabel label={label} optional={optional} />
        </FormLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              {...field}
              fullWidth
              multiple
              input={<OutlinedInput label={placeholder} />}
              onChange={(evt) => { handleChange(evt, onChange)}}
              renderValue={(values) => options.filter(opt => values.includes(opt.value)).map(opt => opt.label).join(', ')}
              value={selected}
              variant="outlined"
              size="small"
              MenuProps={{ disableScrollLock: true }}
              endAdornment={<MuiSelectClearable value={value} onChange={() => { handleClear(onChange)}} />}
            >
              {options.map((option) => (
                <MenuItem key={`option-${option.label}`} value={option.value}>
                  <Checkbox checked={selected.includes(option.value)} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors && <FormHelperText>{errors}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default InputSelectMulti;
