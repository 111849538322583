import React, { useState } from "react";

import clsx from "clsx";
import Dropzone from "react-dropzone";

export const useDropzoneField = (props) => {
  const { fieldName, acceptedFileTypes = ["image/png", "image/jpg", "image/jpeg", "image/pjpeg"] } = props;
  const [fileDropped, setFileDropped] = useState(false);
  const [previewImageURL, setPreviewImageURL] = useState(null);
  const [file, setFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    const reader = new FileReader();
    if (typeof window.FileReader !== "undefined") {
      reader.onload = (e) => {
        setFile(acceptedFiles[0]);
        setFileDropped(true);
        setPreviewImageURL(e.target.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      setFile(acceptedFiles[0]);
      setFileDropped(true);
      setPreviewImageURL(null);
    }
  };

  const dropzoneFilename = () => {
    if (fileDropped) {
      return (
        <>
          File attached:
          <br />
          {file.name}
        </>
      );
    }
    return <></>;
  };

  const renderImagePreview = (header = false) => {
    const previewClass = header ? "sg-mgmt-form-tile-preview-header" : "";
    if (previewImageURL) {
      return (
        <div className="sg-mgmt-form-tile-preview-container">
          <img
            className={clsx("sg-mgmt-hotel-image-preview", previewClass)}
            src={previewImageURL}
            alt="Image Preview"
          />
        </div>
      );
    }
    return <></>;
  };

  const renderFileUpload = () => {
    return (
      <Dropzone accept={acceptedFileTypes} onDrop={onDrop} name={fieldName}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={clsx("dropzone", "sg-mgmt-form-input-dropzone-tile", {
                "dropzone--isActive": isDragActive
              })}
            >
              <input {...getInputProps()} />
              <div className="sg-mgmt-form-input-dropzone-tile-prompt">
                Drag and Drop
                <br />
                or Click to Choose File
              </div>
              <div className="sg-mgmt-form-input-dropzone-tile-filename">{dropzoneFilename()}</div>
            </div>
          );
        }}
      </Dropzone>
    );
  };

  return { file, setPreviewImageURL, renderImagePreview, renderFileUpload };
};
