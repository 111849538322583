import React, { useContext, useEffect, useState } from "react";

import { Stack } from "@mui/material";
import axios from "axios";
import sortBy from "lodash/sortBy";
import Modal from "react-modal";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertHttpError } from "@shared/Alerts";
import { renderCancelButton, renderButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";
import { isDeveloper } from "@shared/UserUtils";

import SessionBlankSession from "./SessionBlankSession";

const SessionModalView = (props) => {
  const { modalVisible, resetModal, sessionId = null } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [fetched, setFetched] = useState(false);
  const [session, setSession] = useState(SessionBlankSession);
  Modal.setAppElement("#root");

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const result = await axios(urljoin(apiRoot, `/sessions/${sessionId}`));
        setSession(result.data.session);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };
    if (sessionId) {
      fetchSession();
    } else {
      setSession(SessionBlankSession);
    }
  }, [apiRoot, sessionId]);

  const tagsString = () => {
    // return session.session_tags.map(tag => tag.name).join(", ");
    return session.session_tags.join(", ");
  };

  const renderField = (label, value) => {
    return (
      <div className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const renderSpeaker = (speaker) => (
    <div key={speaker.gid}>{`${speaker.name_first} ${speaker.name_last} (${speaker.company})`}</div>
  );

  const renderSpeakers = () => {
    if (session.speaker_count === 0) {
      return (
        <div>
          <i>No speakers assigned</i>
        </div>
      );
    }
    const speakers = session.session_participants
      .filter((part) => part.role === "speaker")
      .map((part) => part.event_participant);
    return speakers.map((spk) => renderSpeaker(spk));
  };

  const renderAttendees = () => {
    if (session.attendee_count === 0) {
      return (
        <div>
          <i>No attendees assigned</i>
        </div>
      );
    }
    const attendees = session.session_participants
      .filter((part) => part.role === "attendee")
      .map((part) => part.event_participant);
    return (
      <div className="sg-mgmt-modal-view-attendee-list">
        {sortBy(attendees, ["name_last", "name_first"]).map((att) => (
          <div key={att.gid}>
            {att.name_first} {att.name_last} ({att.email})
          </div>
        ))}
      </div>
    );
  };

  const renderCsvButton = () => (
    <a href={`/reports/events/${event.slug}/attendance_csv_session/${session.id}`} data-turbolinks="false">
      {renderButton("Attendance Export", () => {})}
    </a>
  );

  const renderDeveloper = () => {
    if (isDeveloper(user)) {
      return (
        <div className="sg-mgmt-modal-view-section">
          <h2>Developer Details</h2>
          {renderField("GID", session.gid)}
        </div>
      );
    }
    return <></>;
  };

  const renderModalBody = () => {
    if (fetched) {
      return (
        <>
          <div className="sg-mgmt-modal-view-section">
            <h2>Session Details</h2>
            {renderField("Title", session.title)}
            {renderField("Code", session.code)}
            {renderField("Type", session.session_type_name)}
            {renderField("Track", session.session_track?.name)}
            {renderField("Tags", tagsString())}
          </div>
          <div className="sg-mgmt-modal-view-section">
            <h2>Contact Information</h2>
            {renderField("Description", session.description)}
            {renderField("Length (minutes)", session.length_minutes)}
          </div>
          <div className="sg-mgmt-modal-view-section">
            <h2>Speakers</h2>
            {renderSpeakers()}
          </div>
          <div className="sg-mgmt-modal-view-section">
            <h2>Attendees</h2>
            {renderAttendees()}
          </div>
          {renderDeveloper()}
        </>
      );
    }
    return <Loading />;
  };

  const renderModal = () => {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{`Session: ${session.title}`}</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            {renderModalBody()}
            <Stack direction="row" spacing={2}>
              {renderCancelButton("Done", resetModal)}
              {renderCsvButton()}
            </Stack>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="View Session"
    >
      {renderModal()}
    </Modal>
  );
};

export default SessionModalView;
