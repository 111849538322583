import React from "react";

import { Chip } from "@mui/material";

const SessionStatus = (props) => {
  const { session } = props;
  if (session?.published) {
    return <Chip label="Published" size="small" color="completed" />;
  }
  return <Chip label="Draft" size="small" color="primary" />;
};

export default SessionStatus;
