import { createContext } from "react";

const SessionsSettingsContext = createContext({
  config: null,
  tags: [],
  tracks: [],
  types: [],
  venues: []
});

export default SessionsSettingsContext;
