import React, { useContext } from "react";

import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import EventContext from "@event/EventContext";
import SessionContext from "@sessions/session/SessionContext";
import SessionTitleAndStatus from "@sessions/session/SessionTitleAndStatus";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import InputTextField from "@shared/forms/inputs/InputTextField";
import { renderSubmitButton, renderCancelButton } from "@shared/FormUtils";
import { useDropzoneField } from "@shared/hooks/useDropzoneField";
import { useAddSessionVideo, useDeleteSessionVideo } from "@shared/hooks/useSessionVideos";
import PageHeader from "@shared/PageHeader";

import SessionVideo from "./SessionVideo";

const SessionVideos = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { session } = useContext(SessionContext);
  const addSessionVideo = useAddSessionVideo(apiRoot, event.id, session.id);
  const deleteSessionVideo = useDeleteSessionVideo(apiRoot, event.id, session.id);

  const { file, renderFileUpload } = useDropzoneField({
    fieldName: "session_video[video]",
    acceptedFileTypes: ["video/*"]
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      session_video: {
        name: "",
        video: null,
        external_url: ""
      }
    }
  });

  const submitFn = (data) => {
    const callbacks = {
      onSuccess: (data) => {
        if (data.error === null) {
          alertSuccess("Session video saved");
          reset();
        } else {
          alertError(`Unable to save session video: ${data.error}`);
        }
      },
      onError: (error) => {
        if (error?.response?.data?.error) {
          alertError(error.response?.data?.error);
        } else {
          alertHttpError(error);
        }
      }
    };

    const formData = new FormData();

    for (const key in data.session_video) {
      if (key === "video") {
        formData.append(`session_video[${key}]`, file);
      } else {
        formData.append(`session_video[${key}]`, data.session_video[key]);
      }
    }

    addSessionVideo.mutate({ data: formData }, callbacks);
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" spacing={2}>
        {renderSubmitButton("Save", isSubmitting)}
        {renderCancelButton("Clear", () => reset())}
      </Stack>
    );
  };

  return (
    <div className="max-w-screen-xl">
      <SessionTitleAndStatus />
      <div className="my-4">
        <div className="my-2">
          <PageHeader text="Videos" subHeader />
        </div>
        <div className="mt-4 flex gap-4 w-full flex-wrap">
          {session?.session_videos?.length === 0 && <div>No videos have been uploaded.</div>}
          {session?.session_videos?.map((video) => (
            <SessionVideo key={video.id} session_video={video} deleteMutation={deleteSessionVideo} />
          ))}
        </div>
      </div>
      <div className="my-4">
        <div className="my-2">
          <PageHeader text="Add New Video" subHeader />
        </div>
        <div className="my-2 max-w-screen-md">
          <form onSubmit={handleSubmit(submitFn)}>
            <div>{renderFileUpload()}</div>
            <div>
              <InputTextField
                control={control}
                errors={errors?.session_video?.name}
                label="Video Name"
                optional={false}
                name="session_video[name]"
              />
            </div>
            <div>
              <InputTextField
                control={control}
                errors={errors?.session_video?.external_url}
                label="Video URL (if not attaching a file)"
                optional={false}
                name="session_video[external_url]"
              />
            </div>
            <div>{renderButtons()}</div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SessionVideos;
