import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import { utcToZonedTime } from "date-fns-tz";
import { Field, Formik, Form } from "formik";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import RequiredAsterisk from "@shared/forms/RequiredAsterisk";
import SelectField from "@shared/forms/SelectField";
import {
  renderDateTimeField,
  renderTextField,
  renderTextAreaField,
  renderCancelButton,
  renderButton
} from "@shared/FormUtils";
import Loading from "@shared/Loading";
import { MuiTextField } from "@shared/muiformik/FieldConversions";
import { formatTimeObj } from "@shared/TimeUtils";

import CommunicationsBlankEmail from "./CommunicationsBlankEmail";
import CommunicationsEmailMailingListPreviewModal from "../mailing_lists/CommunicationsEmailMailingListPreviewModal";
import CommunicationsEmailTemplatePreviewModal from "../templates/CommunicationsEmailTemplatePreviewModal";

const CommunicationsEmailForm = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { callbackFailure, callbackSuccess, cancel, email } = props;
  const [config, setConfig] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [lists, setLists] = useState([]);
  const [showCustom, setShowCustom] = useState((email && email.recipients.length > 0) || false);
  const [showListModal, setShowListModal] = useState(false);
  const [previewListId, setPreviewListId] = useState(null);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [previewTemplateId, setPreviewTemplateId] = useState(null);
  const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const isEdit = () => {
    return email && email.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-email-edit",
        formUrl: urljoin(apiRoot, "/communications/emails", `/${email.id}`),
        method: "PATCH",
        saveButton: "Save",
        title: email.name
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-email-add",
      formUrl: urljoin(apiRoot, "/communications/emails"),
      method: "POST",
      saveButton: "Continue",
      title: "Create New Email"
    };
  })();

  useEffect(() => {
    const fetchMailingLists = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/communications/lists"));
        setLists(result.data.lists);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchTemplates = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/communications/email_templates_broadcast"));
        setTemplates(result.data.templates);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchConfig = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/communications/settings"));
        setConfig(result.data.config);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchConfig();
    fetchTemplates();
    fetchMailingLists();
  }, [apiRoot]);

  const fetched = () => {
    return config && config.id && templates && lists;
  };

  const toggleShowCustom = () => {
    setShowCustom(!showCustom);
  };

  const loadDefaultValues = (value, setFieldValue) => {
    const templateId = parseInt(value, 10);
    const template = templates.find(t => t.id === templateId);
    if (template) {
      setFieldValue("email[email_template_id]", templateId);
      setFieldValue("email[subject]", template.default_subject);
      setFieldValue("email[preview_text]", template.default_preview_text);
      setFieldValue("email[from_name]", template.default_from_name);
      setFieldValue("email[from_username]", template.default_from_username);
      setFieldValue("email[reply_address]", template.default_reply_address);
    } else {
      setFieldValue("email[email_template_id]", "0");
      setFieldValue("email[subject]", "");
      setFieldValue("email[preview_text]", "");
      setFieldValue("email[from_name]", "");
      setFieldValue("email[from_username]", "");
      setFieldValue("email[reply_address]", "");
    }
  };

  const handleTemplatePreview = templateId => {
    if (!templateId || templateId === "0") {
      console.log("can't preview template when none is selected");
      return;
    }
    setPreviewTemplateId(templateId);
    setShowTemplateModal(true);
  };

  const handleListPreview = listId => {
    if (!listId || listId === "0") {
      console.log("can't preview list when none is selected");
      return;
    }
    setPreviewListId(listId);
    setShowListModal(true);
  };

  const renderFromUsernameField = (setFieldTouched, setFieldValue) => (
    <div className="sg-mgmt-form-input-container relative">
      <label>
        From Address (username only)
        <RequiredAsterisk />
      </label>
      <Field
        component={MuiTextField}
        size="small"
        fullWidth
        className="sg-mgmt-form-input"
        onChange={e => {
          e.preventDefault();
          const { value } = e.target;
          const regex = /^[A-Za-z0-9._%+-]{0,}$/;
          if (regex.test(value.toString())) {
            setFieldTouched("email[from_username]", false, false);
            setFieldValue("email[from_username]", value);
          }
        }}
        type="text"
        name="email[from_username]"
        autoComplete="one-time-code"
      />
      <div className="absolute bottom-2 right-1">@{`${config.email_domain}`}</div>
    </div>
  );

  const renderMailingListSelect = (label, formatClasses = []) => {
    const options = [
      { value: "0", label: "Select a mailing list (optional)" },
      ...sortBy(lists, [
        e => {
          return e.name.toLowerCase();
        }
      ])
        .filter(l => !l.archived)
        .map(list => ({ value: list.id, label: list.name }))
    ];
    return <SelectField label={label} name="email[email_mailing_list_id]" options={options} formatClasses required />;
  };

  const renderTemplateSelect = (label, setFieldValue, formatClasses = [], required = true) => {
    const options = [
      { label: "Select an email", value: "0" },
      ...sortBy(templates, [
        t => {
          return t.name.toLowerCase();
        }
      ]).map(template => ({ label: template.name, value: template.id }))
    ];
    return (
      <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
        <SelectField
          name="email[email_template_id]"
          options={options}
          onChange={e => {
            loadDefaultValues(e, setFieldValue);
          }}
          label="Email Templates"
          required={true}
        />
      </div>
    );
  };

  const renderSchedulingFields = () => {
    return renderDateTimeField(`Select Date and Time (${browserTz}) (optional)`, "email[scheduled_time]");
  };

  const renderCustomToggle = () => {
    let icon = showCustom ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretRight} />;
    return (
      <div onClick={toggleShowCustom} className="sg-mgmt-link mb-1 cursor-pointer">
        {icon}
        <span className="ml-1 text-sm">Enter manually</span>
      </div>
    );
  };

  const renderLocalTimeZonePreview = timeObj => {
    let formattedTime = "---";
    let abbreviation = "";
    if (timeObj) {
      formattedTime = formatTimeObj(utcToZonedTime(timeObj, event.time_zone));
      abbreviation = new Date()
        .toLocaleTimeString("en-us", {
          timeZone: event.time_zone,
          timeZoneName: "short"
        })
        .split(" ")[2];
    }
    return `Event local time (${event.time_zone}): ${formattedTime} ${abbreviation}`;
  };

  const renderCustom = () => {
    if (showCustom) {
      return (
        <div>
          {renderTextAreaField(
            "Recipients (one per line - NOTE: will only have email address merge tag!)",
            "email[recipients]"
          )}
        </div>
      );
    }
    return <></>;
  };

  const renderListPreviewModal = () => {
    if (fetched && previewListId) {
      return (
        <CommunicationsEmailMailingListPreviewModal
          listId={previewListId}
          modalVisible={showListModal}
          modalClose={() => {
            setShowListModal(false);
            setPreviewListId(null);
          }}
        />
      );
    }
    return <></>;
  };

  const renderTemplatePreviewModal = () => {
    if (fetched && previewTemplateId) {
      return (
        <CommunicationsEmailTemplatePreviewModal
          templateId={previewTemplateId}
          modalVisible={showTemplateModal}
          modalClose={() => {
            setShowTemplateModal(false);
            setPreviewTemplateId(null);
          }}
        />
      );
    }
    return <></>;
  };

  const renderSaveButton = (isSubmitting, setFieldValue, submitForm) => {
    return renderButton(
      "Save",
      () => {
        setFieldValue("email[status]", "saved");
        submitForm();
      },
      {
        disabled: isSubmitting,
        variant: "contained"
      }
    );
  };

  const renderSendButton = (isSubmitting, setFieldValue, submitForm) => {
    return renderButton(
      "Send Now",
      () => {
        setFieldValue("email[status]", "scheduled");
        setFieldValue("email[immediate]", true);
        submitForm();
      },
      {
        disabled: isSubmitting,
        variant: "contained"
      }
    );
  };

  const renderScheduleButton = (isSubmitting, setFieldValue, submitForm) => {
    return renderButton(
      "Schedule Email",
      () => {
        setFieldValue("email[status]", "scheduled");
        setFieldValue("email[immediate]", false);
        submitForm();
      },
      {
        disabled: isSubmitting,
        variant: "contained"
      }
    );
  };

  const renderBackButton = () => {
    return renderCancelButton("Back", cancel);
  };

  const renderButtons = (isSubmitting, setFieldValue, submitForm, scheduledTime) => {
    return (
      <Stack direction="row" spacing={2}>
        {renderSaveButton(isSubmitting, setFieldValue, submitForm)}
        {scheduledTime
          ? renderScheduleButton(isSubmitting, setFieldValue, submitForm)
          : renderSendButton(isSubmitting, setFieldValue, submitForm)}
        {renderBackButton()}
      </Stack>
    );
  };

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        email_template_id: email.email_template_id || "",
        email_mailing_list_id: email.email_mailing_list_id || "",
        from_name: email.from_name,
        from_username: email.from_username,
        immediate: email.immediate,
        name: email.name,
        preview_text: email.preview_text,
        recipients: email.recipients.join("\n"),
        reply_address: email.reply_address,
        scheduled_time: email.scheduled_time ? utcToZonedTime(email.scheduled_time, browserTz) : null,
        status: email.status,
        subject: email.subject,
        tracking_tag: email.tracking_tag
      };
    }
    return CommunicationsBlankEmail;
  };

  const formValidation = values => {
    const errors = {};
    if (values.email.email_template_id === "0") {
      alert("You must select an email template to send");
      errors.email = errors.email || {};
      errors.email.email_template_id = "Required";
    }

    if (values.email.email_mailing_list_id === "0" && values.email.recipients.length === 0) {
      alert("You must either choose a mailing list or enter recipients");
      errors.email = errors.email || {};
      errors.email.email_mailing_list_id = "Required";
    }

    if (values.email.from_username === "") {
      alert("You must enter a username, the message can't just come from nobody.");
      errors.email = errors.email || {};
      errors.email.from_username = "Required";
    }

    if (values.email.subject === "") {
      alert("You must enter a subject.");
      errors.email = errors.email || {};
      errors.email.subject = "Required";
    }

    return errors;
  };

  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const renderForm = () => (
    <Formik
      initialValues={{
        email: formInitialValues()
      }}
      validate={formValidation}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        const form = document.getElementById(formConfig.formId);
        const formData = new FormData(form);
        let abort = false;

        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

        if (values.email.recipients.length > 0) {
          formData.delete("email[recipients]");
          const recipients = values.email.recipients
            .split(/[\s\n,]/)
            .filter(el => !!el)
            .map(el => el.trim());
          recipients.forEach(email => {
            if (validateEmail(email)) {
              formData.append("email[recipients][]", email);
            } else {
              alert(`${email} is not a valid email address`);
              abort = true;
            }
          });
        }

        if (abort) {
          setSubmitting(false);
          return;
        }

        if (
          values.email.email_mailing_list_id === "0" ||
          values.email.email_mailing_list_id === "" ||
          values.email.email_mailing_list_id == null
        ) {
          formData.delete("email[email_mailing_list_id]");
        }

        // If a scheduled time was provided, convert to UTC.
        // If not, set the "immediate" flag as True.
        console.log({
          v: values.email.scheduled_time
        });
        if (values.email.scheduled_time) {
          console.log("Scheduled_time");
          formData.set("email[scheduled_time]", values.email.scheduled_time);
          formData.set("email[immediate]", false);
        } else {
          formData.set("email[immediate]", true);
        }

        // Add status value from button press to formData
        formData.set("email[status]", values.email.status);

        axios({
          url: formConfig.formUrl,
          method: formConfig.method,
          data: formData
        })
          .then(response => {
            if (response.data.error === null) {
              callbackSuccess(response);
              setSubmitting(false);
            } else {
              callbackFailure(response);
              setSubmitting(false);
            }
          })
          .catch(error => {
            alertHttpError(error);
          });
      }}
    >
      {({ isSubmitting, setFieldValue, setFieldTouched, submitForm, values }) => (
        <Form className="sg-mgmt-form" id={formConfig.formId}>
          <div className="sg-mgmt-form-container">
            <div className="sg-mgmt-form-section">
              <div className="w-1/2 py-4">
                <h2>Step 1: Select a Template</h2>
                <div className="float-right">
                  <span
                    className="cursor-pointer text-xs"
                    onClick={() => {
                      handleTemplatePreview(values.email.email_template_id);
                    }}
                  >
                    preview
                  </span>
                </div>
                {renderTemplateSelect("Email Templates", setFieldValue)}
              </div>
              <div className="w-1/2 py-4">
                <h2>Step 2: Enter Recipients</h2>
                <div className="float-right text-xs">
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      handleListPreview(values.email.email_mailing_list_id);
                    }}
                  >
                    preview
                  </span>
                </div>
                {renderMailingListSelect("Mailing List")}
                {renderCustomToggle()}
                {renderCustom()}
              </div>
              <div className="py-4">
                <h2>Step 3: Customize Email</h2>
                {renderTextField("Email Subject Line", "email[subject]", [], false, true)}
                {renderTextField(
                  "Preview Text (optional, message after subject line, not part of actual email)",
                  "email[preview_text]"
                )}
                <div className="flex">
                  <div className="mr-4 w-1/3">{renderTextField("From Name", "email[from_name]", [], false, true)}</div>
                  <div className="mr-4 w-1/3">{renderFromUsernameField(setFieldTouched, setFieldValue)}</div>
                  <div className="w-1/3">
                    {renderTextField("Reply-To Address", "email[reply_address]", [], false, true)}
                  </div>
                </div>
              </div>
              <div className="py-4">
                <h2>Step 4: Set Name and Schedule Email Blast</h2>
                <div>
                  {renderTextField(
                    "Email Name (for reference only, not visible to recipients)",
                    "email[name]",
                    [],
                    false,
                    true
                  )}
                </div>
                <div className="flex items-center">
                  <div className="mr-4 w-1/3">
                    <>{renderSchedulingFields()}</>
                  </div>
                  <div className="mr-4 w-1/3">
                    <span
                      className="sg-mgmt-link"
                      onClick={() => {
                        setFieldValue("email[scheduled_time]", null);
                      }}
                    >
                      Clear Schedule
                    </span>
                    <br />
                    {renderLocalTimeZonePreview(values.email.scheduled_time)}
                  </div>
                  <div className="w-1/3">&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
          <div className="sg-mgmt-form-actions">
            {renderButtons(isSubmitting, setFieldValue, submitForm, values.email.scheduled_time)}
          </div>
        </Form>
      )}
    </Formik>
  );

  const renderBody = () => {
    if (fetched()) {
      return renderForm();
    }
    return <Loading />;
  };

  return (
    <div>
      <h1>{formConfig.title}</h1>
      {renderBody()}
      {renderTemplatePreviewModal()}
      {renderListPreviewModal()}
    </div>
  );
};

CommunicationsEmailForm.defaultProps = {
  callbackFailure: () => { },
  callbackSuccess: () => { },
  cancel: () => { },
  email: CommunicationsBlankEmail
};

CommunicationsEmailForm.propTypes = {
  callbackFailure: PropTypes.func,
  callbackSuccess: PropTypes.func,
  cancel: PropTypes.func,
  email: PropTypes.object
};

export default CommunicationsEmailForm;
