import React, { useContext } from "react";

import { useNavigate } from "react-router";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import SessionsSettingsContext from "@sessions/SessionsSettingsContext";
import { useSessionsSettings } from "@shared/hooks/useSessions";
import Loading from "@shared/Loading";
import RouterTabbedPage from "@shared/RouterTabbedPage";

const SessionNew = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const navigate = useNavigate();
  const sessionsSettingsQuery = useSessionsSettings(apiRoot, event.id);

  const tabs = [{ label: "New Session", path: `/sessions/new` }];

  if (user && user.role == "basic" && !user.permission.sessions_edit) {
    navigate("/sessions");
  }

  if (sessionsSettingsQuery.isLoading || sessionsSettingsQuery.isPending) {
    return <div className="w-full"><Loading /></div>;
  }

  if (sessionsSettingsQuery.isError) {
    return <div className="w-full">Error: {sessionsSettingsQuery.error.message}</div>;
  }

  return (
    <SessionsSettingsContext.Provider
      value={{
        config: sessionsSettingsQuery.data.config,
        tags: sessionsSettingsQuery.data.tags,
        tracks: sessionsSettingsQuery.data.tracks,
        types: sessionsSettingsQuery.data.types,
        venues: sessionsSettingsQuery.data.venues
      }}
    >
      <RouterTabbedPage tabs={tabs} />;
    </SessionsSettingsContext.Provider>
  );
};

export default SessionNew;
