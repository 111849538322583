import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import SessionsContext from "@sessions/SessionsContext";
import SessionsSettingsContext from "@sessions/SessionsSettingsContext";
import { useSessions, useSessionsSettings } from "@shared/hooks/useSessions";
import Loading from "@shared/Loading";
import RouterTabbedPage from "@shared/RouterTabbedPage";
import { TabSettingsIcon } from "@shared/tabs/TabIcons";

const Sessions = () => {
  const { user } = useContext(EventUserContext);
  const { apiRoot, event } = useContext(EventContext).values;
  const sessionsSettingsQuery = useSessionsSettings(apiRoot, event.id);
  const sessionsQuery = useSessions(apiRoot, event.id);

  const getTabs = () => {
    let tabs = [
      { label: "Session Overview", path: "/sessions" },
      { label: "Venues", path: "/sessions/venues" }
    ];

    if (user && (user.role != "basic" || user.permission.sessions_edit)) {
      tabs.push({ label: "Settings", path: "/sessions/settings", icon: TabSettingsIcon });
    }

    return tabs;
  };

  if (sessionsQuery.isPending || sessionsSettingsQuery.isPending) {
    return (
      <div className="w-full">
        <Loading />;
      </div>
    );
  }

  if (sessionsSettingsQuery.isError) {
    return <div className="w-full">Error: {sessionsSettingsQuery.error.message}</div>;
  }

  if (sessionsQuery.isError) {
    return <div className="w-full">Error: {sessionsQuery.error.message}</div>;
  }

  return (
    <SessionsSettingsContext.Provider
      value={{
        config: sessionsSettingsQuery.data.config,
        tags: sessionsSettingsQuery.data.tags,
        tracks: sessionsSettingsQuery.data.tracks,
        types: sessionsSettingsQuery.data.types,
        venues: sessionsSettingsQuery.data.venues
      }}
    >
      <SessionsContext.Provider value={{ sessions: sessionsQuery.data.sessions }}>
        <RouterTabbedPage tabs={getTabs()} />
      </SessionsContext.Provider>
    </SessionsSettingsContext.Provider>
  );
};

export default Sessions;
