import React, { useState } from "react";

import { TextField, FormControl, FormHelperText, FormLabel } from "@mui/material";
import { Controller } from "react-hook-form";

import InputLabel from "@shared/forms/inputs/InputLabel";

const InputNumberField = (props) => {
  const { control, errors, label, min = null, max = null, optional = true, name } = props;
  const [fieldError, setFieldError] = useState(null);

  return (
    <div className="mb-4 w-full">
      <FormControl fullWidth>
        <FormLabel>
          <InputLabel label={label} optional={optional} />
        </FormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <>
              <TextField
                {...field}
                fullWidth
                onChange={(e) => {
                  let value = parseInt(e.target.value, 10);
                  value = isNaN(value) ? 0 : value;
                  if (max && value > max) value = max;
                  if (min && value < min) value = min;
                  e.target.value = value;
                  onChange(e);
                }}
                onError={(error) => setFieldError(error)}
                size="small"
                type="number"
                min={0}
                max={99}
                inputProps={{ min: min, max: max }}
                value={value}
                variant="outlined"
              />
            </>
          )}
        />
        {fieldError && <FormHelperText>{fieldError}</FormHelperText>}
        {errors && <FormHelperText>{errors}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default InputNumberField;
