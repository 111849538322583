import React from "react";

import {
  faFile,
  faFileExcel,
  faFileWord,
  faFilePowerpoint,
  faFilePdf,
  faFileArchive
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Image as ImageIcon,
  VideoCameraFront as VideoCameraFrontIcon
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography
} from "@mui/material";

import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";

const SessionResource = (props) => {
  const { resource, deleteMutation } = props;

  const handleDelete = () => {
    const callbacks = {
      onSuccess: (data) => {
        if (data.error === null) {
          alertSuccess("Session resource saved");
        } else {
          alertError(`Unable to save session resource: ${data.error}`);
        }
      },
      onError: (error) => {
        if (error?.response?.data?.error) {
          alertError(error.response?.data?.error);
        } else {
          alertHttpError(error);
        }
      }
    };

    deleteMutation.mutate({ id: resource.id }, callbacks);
  };

  const renderPlaceholder = () => {
    const mimeType = resource?.mime_type;
    switch (true) {
      case /video/.test(mimeType):
        return (
          <VideoCameraFrontIcon
            sx={{
              width: 96,
              height: 96
            }}
          />
        );

      case /image/.test(mimeType):
        return (
          <ImageIcon
            sx={{
              width: 96,
              height: 96
            }}
          />
        );

      case /zip/.test(mimeType):
        return (
          <FontAwesomeIcon
            icon={faFileArchive}
          />
        );

      case /pdf/.test(mimeType):
        return (
          <FontAwesomeIcon
            icon={faFilePdf}
          />
        );

      case /(excel|spreadsheet)/.test(mimeType):
        return (
          <FontAwesomeIcon
            icon={faFileExcel}
          />
        );

      case /(powerpoint|presentation)/.test(mimeType):
        return (
          <FontAwesomeIcon
            icon={faFilePowerpoint}
          />
        );

      case /(word|document)/.test(mimeType):
        return (
          <FontAwesomeIcon
            icon={faFileWord}
          />
        );

      default:
        return (
          <FontAwesomeIcon
            icon={faFile}
          />
        );
    };
  };

  return (
    <div className="w-[340] flex-none">
      <Card sx={{ width: 340 }}>
        {resource.thumbnail_url && (
          <CardMedia
            component="img"
            alt={`${resource.name} thumbnail`}
            sx={{
              backgroundColor: "#EAEAEA",
              maxHeight: 190,
              maxWidth: 340,
              objectFit: "contain"
            }}
            image={resource.thumbnail_url}
          />
        )}
        {!resource.thumbnail_url && (
          <CardMedia
            component="div"
            sx={{
              color: "#aaaaaa",
              paddingTop: "48px",
              width: 340,
              height: 190,
              backgroundColor: "#EAEAEA",
              margin: "0 auto",
              textAlign: "center"
            }}
          >
            <div className="text-8xl">
              {renderPlaceholder()}
            </div>
          </CardMedia>
        )}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {resource.name}
         </Typography>
        </CardContent>
        <CardActions>
          <a href={resource.file_url} target="_blank" rel="noreferrer">
            <Button size="small">Download</Button>
          </a>
          <Button onClick={handleDelete} size="small">Remove</Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default SessionResource;
