import React, { useContext } from "react";

import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import EventContext from "@event/EventContext";
import SessionContext from "@sessions/session/SessionContext";
import SessionTitleAndStatus from "@sessions/session/SessionTitleAndStatus";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import InputTextField from "@shared/forms/inputs/InputTextField";
import { renderSubmitButton, renderCancelButton } from "@shared/FormUtils";
import { useDropzoneField } from "@shared/hooks/useDropzoneField";
import { useAddSessionResource, useDeleteSessionResource } from "@shared/hooks/useSessionResources";
import PageHeader from "@shared/PageHeader";

import SessionResource from "./SessionResource";

const SessionResources = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { session } = useContext(SessionContext);
  const addSessionResource = useAddSessionResource(apiRoot, event.id, session.id);
  const deleteSessionResource = useDeleteSessionResource(apiRoot, event.id, session.id);

  const { file, renderFileUpload } = useDropzoneField({
    fieldName: "resource[file]",
    acceptedFileTypes: [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "text/plain",
      "text/csv",
      "text/html",
      "text/xml",
      "image/*",
      "application/zip"
    ]
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      resource: {
        name: "",
        file: ""
      }
    }
  });

  const submitFn = (data) => {
    const callbacks = {
      onSuccess: (data) => {
        if (data.error === null) {
          alertSuccess("Session resource saved");
          reset();
        } else {
          alertError(`Unable to save session resource: ${data.error}`);
        }
      },
      onError: (error) => {
        if (error?.response?.data?.error) {
          alertError(error.response?.data?.error);
        } else {
          alertHttpError(error);
        }
      }
    };

    const formData = new FormData();

    for (const key in data.resource) {
      if (key === "file") {
        formData.append(`resource[${key}]`, file);
      } else {
        formData.append(`resource[${key}]`, data.resource[key]);
      }
    }

    addSessionResource.mutate({ data: formData }, callbacks);
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" spacing={2}>
        {renderSubmitButton("Save", isSubmitting)}
        {renderCancelButton("Clear", () => reset())}
      </Stack>
    );
  };

  return (
    <div className="max-w-screen-xl">
      <SessionTitleAndStatus />
      <div className="my-4">
        <div className="my-2">
          <PageHeader text="Resources" subHeader />
        </div>
        <div className="mt-4 flex gap-4 w-full flex-wrap">
          {session.resources.length === 0 && <div>No resources have been uploaded.</div>}
          {session.resources.sort((a,b) => a.name - b.name).map((resource) => (
            <SessionResource key={resource.id} resource={resource} deleteMutation={deleteSessionResource} />
          ))}
        </div>
      </div>
      <div className="my-4">
        <div className="my-2">
          <PageHeader text="Add New Resource" subHeader />
        </div>
        <div className="my-2 max-w-screen-md">
          <form onSubmit={handleSubmit(submitFn)}>
            <div>{renderFileUpload()}</div>
            <div>
              <InputTextField
                control={control}
                errors={errors?.resource?.name}
                label="Resource Name"
                optional={false}
                name="resource[name]"
              />
            </div>
            <div>{renderButtons()}</div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SessionResources;
