import React, { useContext, useState } from "react";

import { faCaretDown, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";

import EventContext from "@event/EventContext";
import GrowlTable from "@shared/GrowlTable";

const SessionsTable = (props) => {
  const { keyBase, title } = props;
  const { event } = useContext(EventContext).values;
  const [visible, setVisible] = useState(true);

  const toggleVisible = () => {
    setVisible(!visible);
  };

  const renderVisibilityArrow = () => {
    if (visible) {
      return (
        <span>
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      );
    }
    return (
      <span>
        <FontAwesomeIcon icon={faCaretLeft} />
      </span>
    );
  };

  const renderTable = () => {
    if (!visible) {
      return <></>;
    }

    return (
      <div className="w-full" key={`${keyBase}-sessions`}>
        <Box width="100%">
          <div></div>
          <GrowlTable tableName={`${event.slug}-${keyBase}`} {...props} />
        </Box>
      </div>
    );
  };

  return (
    <div className="mv-8 mt-4">
      <div className="cursor-pointer" onClick={toggleVisible}>
        <h2>
          {title}
          <span className="ml-2">{renderVisibilityArrow()}</span>
        </h2>
      </div>
      {renderTable()}
    </div>
  );
};

export default SessionsTable;
