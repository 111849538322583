import React, { useMemo, useState } from "react";

import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";
import { Controller } from "react-hook-form";

import InputLabel from "@shared/forms/inputs/InputLabel";

const InputDate = (props) => {
  const { control, errors, label, minDate = null, maxDate = null, optional = true, name } = props;
  const [fieldError, setFieldError] = useState(null);

  const errorMessage = useMemo(() => {
    switch (fieldError) {
      case "maxDate": {
        return "Date is later than maximum allowed date";
      }
      case "minDate": {
        return "Date is earlier than minimum allowed date";
      }

      case "invalidDate": {
        return "Date is not valid";
      }

      default: {
        return "";
      }
    }
  }, [fieldError]);

  return (
    <div className="mb-4 w-full">
      <FormControl fullWidth>
        <FormLabel>
          <InputLabel label={label} optional={optional} />
        </FormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <DatePicker
              {...field}
              format="yyyy-MM-dd"
              fullWidth
              maxDate={maxDate ? parseISO(maxDate) : null}
              minDate={minDate ? parseISO(minDate) : null}
              onChange={(date) => {
                onChange(date);
              }}
              onError={(error) => {
                setFieldError(error);
              }}
              rules={{
                minDate: { message: "Date is before minimum date" },
                maxDate: { message: "Date is after maximum date" },
                invalidDate: { message: "Invalid date" }
              }}
              timezone="system"
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  helperText: errorMessage
                },
                actionBar: {
                  actions: ["clear"]
                }
              }}
              value={value}
            />
          )}
        />
        {errors && <FormHelperText>{errors}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default InputDate;
