import React from "react";

import { VideoCameraFront as VideoCameraFrontIcon } from '@mui/icons-material';
import {
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography
} from "@mui/material";

import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";

const SessionVideo = (props) => {
  const { session_video, deleteMutation } = props;

  const handleDelete = () => {
    const callbacks = {
      onSuccess: (data) => {
        if (data.error === null) {
          alertSuccess("Session video saved");
        } else {
          alertError(`Unable to save session video: ${data.error}`);
        }
      },
      onError: (error) => {
        if (error?.response?.data?.error) {
          alertError(error.response?.data?.error);
        } else {
          alertHttpError(error);
        }
      }
    };

    deleteMutation.mutate({ id: session_video.id }, callbacks);
  };

  return (
    <div className="w-[340] flex-none">
      <Card sx={{ width: 340 }}>
        {session_video.screenshot_url && (
          <CardMedia
            component="img"
            alt={`${session_video.name} thumbnail`}
            sx={{
              backgroundColor: "#EAEAEA",
              maxHeight: 190,
              maxWidth: 340,
              objectFit: "contain"
            }}
            image={session_video.screenshot_url}
          />
        )}
        {!session_video.screenshot_url && (
          <CardMedia
            component="div"
            sx={{
              color: "#999999",
              paddingTop: "48px",
              width: 340,
              height: 190,
              backgroundColor: "#EAEAEA",
              margin: "0 auto",
              textAlign: "center"
            }}
          >
            <VideoCameraFrontIcon
              sx={{
                width: 96,
                height: 96
              }}
            />
          </CardMedia>
        )}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {session_video.name}
         </Typography>
        </CardContent>
        <CardActions>
          <a href={session_video.video_url} target="_blank" rel="noreferrer">
            <Button size="small">Download</Button>
          </a>
          <Button onClick={handleDelete} size="small">Remove</Button>
        </CardActions>
      </Card>
    </div>
  );

  // return (
  //   <div className="max-w-screen-md">
  //     <div className="mb-2 flex justify-start">
  //       <div className="flex-initial pr-8 font-semibold">{session_video.name}</div>
  //       <div className="flex-none border-r border-ui-1 pr-4">
  //         <a className="sg-mgmt-link" href={session_video.file_url} target="_blank" rel="noreferrer">
  //           Download
  //         </a>
  //       </div>
  //       <div className="flex-none cursor-pointer pl-4">
  //         <span className="text-sg-orange hover:underline" onClick={handleDelete}>
  //           Remove
  //         </span>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default SessionVideo;
