import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import { format, utcToZonedTime } from "date-fns-tz";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import { renderButton } from "@shared/FormUtils";
import GrowlModal from "@shared/GrowlModal";
import GrowlTable from "@shared/GrowlTable";
import Iframe from "@shared/Iframe";
import Loading from "@shared/Loading";
import { formatTime, formatTimeUtcToZone } from "@shared/TimeUtils";
import UiToggle from "@shared/UiToggle";

const CommunicationsEmail = (props) => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { back, clickedTracking, clickedView, emailId, editClick } = props;
  const [email, setEmail] = useState([]);
  const [activity, setActivity] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [showDetails, setShowDetails] = useState(clickedView);
  const [showErrors, setShowErrors] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showTracking, setShowTracking] = useState(clickedTracking);

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const result = await axios(urljoin(apiRoot, `/communications/emails/${emailId}`));
        setEmail(result.data.email);
        setActivity(result.data.activity);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchEmail();
  }, [apiRoot, emailId]);

  const renderPostmarkTimestamp = (timestamp) => {
    const convertedTimestamp = utcToZonedTime(timestamp, event.time_zone);
    return `${format(convertedTimestamp, "yyyy-MM-dd hh:mm aaa zzz", {
      timeZone: event.time_zone
    })}`;
  };

  const renderRecipients = () => {
    const recipients = [];
    if (email.list_name) {
      recipients.push(
        <div key="list-name">
          <span className="font-bold">Mailing List:</span> {email.list_name}
        </div>
      );
    }
    if (email.recipients.length > 0) {
      recipients.push(
        <div key="recipient-list">
          <span className="font-bold">Custom Recipients:</span>
          <br />
          {email.recipients.join(<br />)}
        </div>
      );
    }
    return recipients;
  };

  const renderCustomization = () => {
    return (
      <>
        <div>
          <span className="font-bold">Email Subject Line:</span> {email.subject}
        </div>
        <div>
          <span className="font-bold">Preview Text:</span> {email.preview_text}
        </div>
        <div>
          <span className="font-bold">From Name:</span> {email.from_name}
        </div>
        <div>
          <span className="font-bold">From Address:</span> {`${email.from_username}@${email.from_domain}`}
        </div>
        <div>
          <span className="font-bold">Reply-To Address:</span> {email.reply_address}
        </div>
      </>
    );
  };

  const renderStatus = () => {
    switch (email.status) {
      case "saved":
        return (
          <div className="flex">
            <div className="w-28 font-bold text-sg-saved">Saved</div>
            <div>{formatTime(email.updated_at, event.time_zone)}</div>
          </div>
        );
      case "scheduled":
        return (
          <div className="flex">
            <div className="w-28 font-bold text-sg-yellow">Scheduled</div>
            <div>{formatTime(email.scheduled_time_local, event.time_zone)}</div>
          </div>
        );
      case "processed":
        return (
          <div className="flex">
            <div className="w-28 font-bold text-sg-completed">Completed</div>
            <div>{formatTimeUtcToZone(email.completed_timestamp, event.time_zone)}</div>
          </div>
        );
      case "error":
        return (
          <div className="flex">
            <div className="w-28 font-bold text-sg-accent">Error</div>
            <div>{formatTime(email.updated_at, event.time_zone)}</div>
          </div>
        );
      default:
        alert("Invalid status");
        return <></>;
    }
  };

  const renderDetails = () => {
    if (showDetails) {
      return (
        <table className="ml-2 w-3/4">
          <tbody>
            <tr className="pb-1">
              <td className="w-36 p-2 align-top font-bold text-sg-orange">Name</td>
              <td className="p-2 align-top">{email.name}</td>
            </tr>
            <tr className="pb-1">
              <td className="p-2 align-top font-bold text-sg-orange">Recipients</td>
              <td className="p-2 align-top">{renderRecipients(email)}</td>
            </tr>
            <tr className="pb-1">
              <td className="p-2 align-top font-bold text-sg-orange">Customization</td>
              <td className="p-2 align-top">{renderCustomization(email)}</td>
            </tr>
            <tr className="pb-1">
              <td className="p-2 align-top font-bold text-sg-orange">Status</td>
              <td className="p-2 align-top">{renderStatus(email)}</td>
            </tr>
            <tr className="pb-1">
              <td className="p-2 align-top font-bold text-sg-orange">GID</td>
              <td className="p-2 align-top">{email.gid}</td>
            </tr>
          </tbody>
        </table>
      );
    }
    return <></>;
  };

  const renderErrors = () => {
    if (showErrors) {
      return (
        <div>
          <div className="my-2">
            <div className="font-bold">Errors:</div>
            <table className="ml-2 w-3/4">
              <tbody>
                {Object.keys(email.send_errors).map((key) => (
                  <tr key={key}>
                    <td className="w-36 pr-2 align-top font-bold">{key}</td>
                    <td className="align-top">{email.send_errors[key]}</td>
                  </tr>
                ))}
                {Object.keys(email.send_errors).length === 0 && <div className="font-italic my-1">None</div>}
              </tbody>
            </table>
          </div>
          <div className="my-2">
            <div className="font-bold">Skipped:</div>
            <table className="ml-2 w-3/4">
              <tbody>
                {Object.keys(email.send_skipped).map((key) => (
                  <tr key={key}>
                    <td className="w-36 pr-2 align-top font-bold">{key}</td>
                    <td className="align-top">{email.send_skipped[key]}</td>
                  </tr>
                ))}
                {Object.keys(email.send_skipped).length === 0 && <div className="font-italic my-1">None</div>}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderPreview = () => {
    if (showPreview) {
      if ("email_template" in email) {
        return <Iframe scrolling={"no"} content={email.email_template.html} />;
      }
    }
    return <></>;
  };

  const renderField = (label, value) => {
    return (
      <div key={label} className="sg-mgmt-modal-view-field mb-1">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const renderItemDetails = (item) => {
    switch (item.activity_type) {
      case "delivered":
        return (
          <div>
            {renderField("Delivery Message", item.summary)}
            {renderField("Destination Server", item.delivery_server)}
            {renderField("Destination IP", item.delivery_ip)}
            {renderField("MSID", item.msid)}
          </div>
        );
      case "opened":
        return (
          <div>
            {renderField("Summary", item.summary)}
            {renderField("MSID", item.msid)}
          </div>
        );
      case "clicked":
        return (
          <div>
            {renderField("Summary", item.summary)}
            {renderField("Link", <a href={`${item.click_link}`}>{item.click_link}</a>)}
            {renderField("Click Location", item.click_location)}
            {renderField("MSID", item.msid)}
          </div>
        );
      default:
        return (
          <div>
            {renderField("Summary", item.summary)}
            {renderField("MSID", item.msid)}
          </div>
        );
    }
  };

  const columns = [
    {
      headerName: "Type",
      field: "activity_type",
      type: "singleSelect",
      flex: 1,
      valueOptions: ["delivered", "opened", "clicked", "bounced", "subchanged", "transient", "unknown"]
    },
    {
      field: "recipient",
      headerName: "Recipient",
      flex: 1
    },
    {
      headerName: "Timestamp",
      field: "timestamp",
      flex: 1,
      renderCell: (params) => renderPostmarkTimestamp(params.value)
    },
    {
      headerName: "Details",
      field: "details",
      flex: 1,
      renderCell: (params) => (
        <GrowlModal
          content={renderItemDetails(params.row)}
          title="Details"
          trigger={<span className="sg-mgmt-link cursor-pointer">View</span>}
          actions={[
            {
              label: "Close",
              close: true
            }
          ]}
        />
      )
    }
  ];
  /* eslint-enable react/prop-types, react/display-name */

  const renderTrackingTable = () => {
    if (showTracking) {
      return (
        <div>
          <GrowlTable columns={columns} items={activity} tableName={`${event.slug}-email-activity`} />
        </div>
      );
    }
    return <></>;
  };

  const renderBackButton = () => {
    return renderButton("Done", back);
  };

  const renderEditButton = () => {
    if (email.status === "processed") {
      return <></>;
    }
    return (
      <div className="float-right">
        {renderButton("Edit", () => editClick(email), {
          variant: "primary"
        })}
      </div>
    );
  };

  const renderBody = () => {
    if (fetched) {
      return (
        <div>
          <div>
            {renderEditButton()}
            <h1>{email.name}</h1>
          </div>
          <div className="border-t border-solid border-sg-orange py-3">
            <UiToggle
              flag={showDetails}
              label="Email Details"
              toggleFunc={() => {
                setShowDetails(!showDetails);
              }}
            />
            {renderDetails()}
          </div>
          <div className="border-t border-solid border-sg-orange py-3">
            <UiToggle
              flag={showPreview}
              label="Preview"
              toggleFunc={() => {
                setShowPreview(!showPreview);
              }}
            />
            {renderPreview()}
          </div>
          <div className="border-t border-solid border-sg-orange py-3">
            <UiToggle
              flag={showErrors}
              label="Errors and Skipped"
              toggleFunc={() => {
                setShowErrors(!showErrors);
              }}
            />
            {renderErrors()}
          </div>
          <div className="border-t border-solid border-sg-orange py-3">
            <UiToggle
              flag={showTracking}
              label="Tracking"
              toggleFunc={() => {
                setShowTracking(!showTracking);
              }}
            />
            {renderTrackingTable()}
          </div>
        </div>
      );
    }
    return <Loading />;
  };

  const renderEmail = () => <div>{renderBody()}</div>;

  return (
    <div>
      {renderEmail()}
      <div className="py-3">{renderBackButton()}</div>
    </div>
  );
};

CommunicationsEmail.propTypes = {
  back: PropTypes.func.isRequired,
  clickedTracking: PropTypes.bool.isRequired,
  clickedView: PropTypes.bool.isRequired,
  emailId: PropTypes.number.isRequired,
  editClick: PropTypes.func.isRequired
};

export default CommunicationsEmail;
