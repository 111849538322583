import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchSession = async (apiRoot, sessionId, signal) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, `/sessions/${sessionId}`),
    signal: signal
  });
};

const fetchSessions = async (apiRoot, signal) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/sessions"),
    signal: signal
  });
};

const fetchSessionsSettings = async (apiRoot, signal) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/sessions/settings"),
    signal: signal
  });
};

const addSessionAssignment = async (apiRoot, sessionId, data) => {
  return request({
    data: data,
    method: "POST",
    url: urljoin(apiRoot, `/sessions/${sessionId}/participants`)
  });
};

const removeSessionAssignment = async (apiRoot, sessionId, data) => {
  return request({
    data: data,
    method: "DELETE",
    url: urljoin(apiRoot, `/sessions/${sessionId}/participants`)
  });
};

const addSession = async (apiRoot, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, `/sessions`),
    data: { session: data }
  });
};

const addSessionTag = async (apiRoot, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, `/sessions/settings/create_tag`),
    data: { session_tag: data }
  });
};

const addSessionTrack = async (apiRoot, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, `/session_tracks`),
    data: data
  });
};

const addSessionType = async (apiRoot, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, `/session_types`),
    data: data
  });
};

const deleteSession = async (apiRoot, id) => {
  return request({
    method: "DELETE",
    url: urljoin(apiRoot, `/sessions/${id}`)
  });
};

const cloneSession = async (apiRoot, id) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, `/sessions/${id}/clone`)
  });
};

const deleteSessionTag = async (apiRoot, id) => {
  return request({
    method: "DELETE",
    url: urljoin(apiRoot, `/sessions/settings/delete_tag`),
    data: { tag_id: id }
  });
};

const deleteSessionTrack = async (apiRoot, id) => {
  return request({
    method: "DELETE",
    url: urljoin(apiRoot, `/session_tracks/${id}`)
  });
};

const deleteSessionType = async (apiRoot, id) => {
  return request({
    method: "DELETE",
    url: urljoin(apiRoot, `/session_types/${id}`)
  });
};

const updateSession = async (apiRoot, id, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/sessions/${id}`),
    data: { session: data }
  });
};

const updateSessionsSettings = async (apiRoot, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/sessions/settings`),
    data: data
  });
};

export const useAddSession = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addSession(apiRoot, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["sessions", eventId]);
    }
  });
};

export const useCloneSession = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => cloneSession(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["sessions", eventId]);
    }
  });
};

export const useDeleteSession = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => deleteSession(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["sessions", eventId]);
    }
  });
};

export const useSession = (apiRoot, eventId, sessionId) => {
  return useQuery({
    queryKey: ["session", eventId, sessionId],
    queryFn: ({ signal }) => fetchSession(apiRoot, sessionId, signal),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useSessions = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["sessions", eventId],
    queryFn: ({ signal }) => fetchSessions(apiRoot, signal),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useSessionsSettings = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["sessionsSettings", eventId],
    queryFn: ({ signal }) => fetchSessionsSettings(apiRoot, signal),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useRefreshSessions = (eventId) => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({ queryKey: ["sessions", eventId] });
  };
};

export const useAddSessionAssignment = (apiRoot, eventId, sessionId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addSessionAssignment(apiRoot, sessionId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["session", eventId, sessionId]);
    }
  });
};

export const useRemoveSessionAssignment = (apiRoot, eventId, sessionId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => removeSessionAssignment(apiRoot, sessionId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["session", eventId, sessionId]);
    }
  });
};

export const useAddSessionTag = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addSessionTag(apiRoot, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["sessionsSettings", eventId]);
    }
  });
};

export const useDeleteSessionTag = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => deleteSessionTag(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["sessionsSettings", eventId]);
    }
  });
};

export const useAddSessionType = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addSessionType(apiRoot, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["sessionsSettings", eventId]);
    }
  });
};

export const useDeleteSessionType = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => deleteSessionType(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["sessionsSettings", eventId]);
    }
  });
};

export const useAddSessionTrack = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addSessionTrack(apiRoot, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["sessionsSettings", eventId]);
    }
  });
};

export const useDeleteSessionTrack = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => deleteSessionTrack(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["sessionsSettings", eventId]);
    }
  });
};

export const useUpdateSession = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) => updateSession(apiRoot, id, data),
    onMutate: async (session) => {
      await queryClient.cancelQueries({ queryKey: ["session", eventId, session.id] });
      const previousSession = queryClient.getQueryData(["session", eventId, session.id]);
      queryClient.removeQueries(["session", eventId, session.id]);
      queryClient.setQueryData(["session", eventId, session.id], session);
      return { previousSession, session };
    },
    onError: (_err, _session, context) => {
      queryClient.setQueryData(["sessions", eventId, context.session.id], context.previousSession);
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["sessions", eventId] });
    }
  });
};

export const useUpdateSessionsSettings = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => updateSessionsSettings(apiRoot, data),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ["sessionsSettings", eventId] });
      queryClient.removeQueries(["sessionsSettings", eventId]);
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["sessionsSettings", eventId] });
    }
  });
};
