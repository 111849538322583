import React from "react";

import SessionAssignment from "@sessions/session/SessionAssignment";

const SessionSpeakers = () => {
  return (
    <SessionAssignment
      role="speaker"
      roleLabel="Speaker"
      description={
        "Assign and unassign speakers to this particular session. If you don’t see a speaker, please go to Participants and add the speaker there. If the person already exists but doesn't show in this table, please make sure the participant's Role is set to Speaker."
      }
    />
  );
};

export default SessionSpeakers;
