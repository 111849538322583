import React from "react";

import SessionAssignment from "@sessions/session/SessionAssignment";

const SessionAttendees = () => {
  return (
    <SessionAssignment
      role="attendee"
      roleLabel="Attendee"
      description={
        "Assign and unassign attendees to this particular session. Use this if you want to manage who is attending what (i.e. assign sessions by attendee type or track). This feature is especially useful when using a schedule builder or personalized agendas."
      }
    />
  );
};

export default SessionAttendees;
