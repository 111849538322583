import { useMutation, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const addSessionResource = async (apiRoot, sessionId, data) => {
  return request({
    data: data,
    method: "POST",
    url: urljoin(apiRoot, `/sessions/${sessionId}/resources`)
  });
};

const deleteSessionResource = async (apiRoot, sessionId, resourceId) => {
  return request({
    method: "DELETE",
    url: urljoin(apiRoot, `/sessions/${sessionId}/resources/${resourceId}`)
  });
};

export const useAddSessionResource = (apiRoot, eventId, sessionId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addSessionResource(apiRoot, sessionId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["session", eventId, sessionId]);
    }
  });
};

export const useDeleteSessionResource = (apiRoot, eventId, sessionId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => deleteSessionResource(apiRoot, sessionId, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["session", eventId, sessionId]);
    }
  });
};
