import React, { useContext, useState } from "react";

import Stack from "@mui/material/Stack";
import axios from "axios";
import clsx from "clsx";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError, alertError } from "@shared/Alerts";
import { renderCancelButton, renderButton, renderDeleteButton } from "@shared/FormUtils";

const UploadStaticFileModal = (props) => {
  const { apiRoot } = useContext(EventContext).values;
  const { modalVisible, resetModal, callbackSuccess } = props;

  Modal.setAppElement("#root");
  const [currentFiles, setCurrentFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const renderForm = () => {
    return (
      <div>
        {renderDropzone()}
        {renderFiles()}
        {renderButtons()}
      </div>
    );
  };

  const renderFiles = () => {
    return <div>{currentFiles.map((file, i) => renderFilePreview(file, i))}</div>;
  };

  const removeFile = (i) => {
    setCurrentFiles(currentFiles.slice(0, i).concat(currentFiles.slice(i + 1)));
  };

  const renderFilePreview = (file, i) => {
    return (
      <div className="flex">
        <div className="grow">{file.name}</div>
        {renderDeleteButton("Remove", () => removeFile(i))}
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" spacing={2}>
        {renderButton("Upload", uploadFiles, { color: "secondary" })}
        {renderCancelButton("Cancel", resetModal)}
      </Stack>
    );
  };

  const uploadFiles = () => {
    setSubmitting(true);
    const formData = new FormData();

    currentFiles.forEach((file) => {
      formData.append("static_file[]", file);
    });

    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      url: urljoin(apiRoot, "contents/static_files"),
      method: "POST",
      data: formData
    })
      .then((response) => {
        if (response.data.error == "") {
          callbackSuccess(response.data.static_files);
          setCurrentFiles([]);
          setSubmitting(false);
          resetModal();
        } else {
          console.log({
            response
          });
          alertHttpError(response.data.error);
          setCurrentFiles([]);
          setSubmitting(false);
        }
      })
      .catch((error) => {
        alertError(error);
        console.log({
          error
        });
        setSubmitting(false);
      });
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    setCurrentFiles(currentFiles.concat(acceptedFiles));
  };

  const renderDropzone = () => {
    return (
      <Dropzone onDrop={onDrop} name="import[]">
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <>
              <div
                {...getRootProps()}
                className={clsx("dropzone", "sg-mgmt-form-input-static-file-drop", {
                  "dropzone--isActive": isDragActive
                })}
              >
                <input {...getInputProps()} />
                Drop files here
              </div>
            </>
          );
        }}
      </Dropzone>
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Edit Ticket Type"
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Upload Files</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">{modalVisible ? renderForm() : ""}</div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadStaticFileModal;
