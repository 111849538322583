import { useMutation, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const addSessionVideo = async (apiRoot, sessionId, data) => {
  return request({
    data: data,
    method: "POST",
    url: urljoin(apiRoot, `/sessions/${sessionId}/session_videos`)
  });
};

const deleteSessionVideo = async (apiRoot, sessionId, videoId) => {
  return request({
    method: "DELETE",
    url: urljoin(apiRoot, `/sessions/${sessionId}/session_videos/${videoId}`)
  });
};

export const useAddSessionVideo = (apiRoot, eventId, sessionId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addSessionVideo(apiRoot, sessionId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["session", eventId, sessionId]);
    }
  });
};

export const useDeleteSessionVideo = (apiRoot, eventId, sessionId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => deleteSessionVideo(apiRoot, sessionId, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["session", eventId, sessionId]);
    }
  });
};
