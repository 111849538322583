import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchCustomReports = async (apiRoot) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/reports")
  });
};

const deleteReport = async (apiRoot, id) => {
  return request({
    method: "delete",
    url: urljoin(apiRoot, "/reports", `/${id}`)
  });
};

const cloneReport = async (apiRoot, id) => {
  return request({
    method: "post",
    url: urljoin(apiRoot, "/reports", `/${id}`, "/clone")
  });
};

export const useCustomReports = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["customReports", eventId],
    queryFn: () => fetchCustomReports(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useDeleteReport = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => deleteReport(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["customReports", eventId]);
    }
  });
};

export const useCloneReport = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => cloneReport(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["customReports", eventId]);
    }
  });
};

export const useRefreshCustomReports = (eventId) => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({ queryKey: ["customReports", eventId] });
  };
};
