import React, { useContext, useState } from "react";

import { useConfirm } from "material-ui-confirm";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import {
  useCustomReports,
  useCloneReport,
  useDeleteReport,
  useRefreshCustomReports
} from "@shared/hooks/useCustomReports";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import CustomReportDownload from "./CustomReportDownload";
import CustomReportEdit from "./CustomReportEdit";
import CustomReportNew from "./CustomReportNew";

const CustomReports = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [page, setPage] = useState("home");
  const [editReportId, setEditReportId] = useState(null);
  const confirm = useConfirm();
  const reports = useCustomReports(apiRoot, event.id);
  const deleteReport = useDeleteReport(apiRoot, event.id);
  const cloneReport = useCloneReport(apiRoot, event.id);
  const refreshReports = useRefreshCustomReports(event.id);

  /* eslint-disable react/prop-types, react/display-name */
  const columns = [
    {
      field: "name",
      headerName: "Report Name"
    },
    {
      field: "report_type",
      headerName: "Report Type",
      renderCell: (params) => {
        return reportTypeLabel(params.row.report_type);
      }
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: (params) => [
        renderDirectDownloadLink(params.row),
        renderDownloadLink(params.row),
        renderCloneLink(params.row),
        renderEditLink(params.row),
        renderDeleteLink(params.row)
      ]
    }
  ];

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.reports_edit) {
      return false;
    }
    return true;
  };

  const reportTypeLabel = (report_type) => {
    switch (report_type) {
      case "session":
        return "Sessions";
      case "event_participant":
        return "Participants";
      case "participant_ticket":
        return "Tickets";
      default:
        return "Unknown";
    }
  };

  const renderDirectDownloadLink = (report) => {
    if (ESB_RAILS_ENV !== "development") {
      return <></>;
    }

    return (
      <>
        <span className="cursor-pointer">
          <a href={urljoin(apiRoot, "/reports", `/${report.id}`, "/xlsx")}>Direct</a>
        </span>
      </>
    );
  };

  const renderDownloadLink = (report) => {
    return <CustomReportDownload url={urljoin(apiRoot, "/reports", `/${report.id}`, "/download")} />;
  };

  const renderEditLink = (report) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            setEditReportId(report.id);
            setPage("edit");
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const renderCloneLink = (report) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            cloneReport.mutate(report.id);
          }}
        >
          Clone
        </span>
      </>
    );
  };

  const renderDeleteLink = (report) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            confirm({
              title: "Confirm removal",
              description: "Are you sure you want to remove this report? This cannot be undone."
            }).then(() => {
              deleteReport.mutate(report.id);
            });
          }}
        >
          Delete
        </span>
      </>
    );
  };

  const renderReports = () => {
    return <GrowlTable tableName="Custom Reports" columns={columns} items={reports.data.reports} />;
  };

  const renderNewButton = () => {
    if (!editEnabled()) {
      return <></>;
    }

    return <>{renderCreateButton("New Report", () => setPage("new"))}</>;
  };

  const renderHome = () => {
    return (
      <>
        {renderNewButton()}
        <div>{renderReports()}</div>
      </>
    );
  };

  const renderNew = () => {
    return <CustomReportNew goBack={() => setPage("home")} refreshReports={refreshReports} />;
  };

  const renderEdit = () => {
    return <CustomReportEdit goBack={() => setPage("home")} refreshReports={refreshReports} reportId={editReportId} />;
  };

  const renderContent = () => {
    switch (page) {
      case "home":
        return renderHome();
      case "new":
        if (!editEnabled()) {
          return renderHome();
        }

        return renderNew();
      case "edit":
        if (!editEnabled()) {
          return renderHome();
        }

        return renderEdit();
      default:
        throw new Error("invalid reports page");
    }
  };

  if (reports.isLoading) {
    return <Loading />;
  }

  if (reports.isError) {
    return <div>Error: {reports.error.message}</div>;
  }

  return (
    <div className="sg-mgmt-content-frame">
      <div className="sg-mgmt-content sg-mgmt-content-full">
        <PageHeader text="Custom Reports" />
        <div className="mt-2">{renderContent()}</div>
      </div>
    </div>
  );
};

export default CustomReports;
