import React, { useContext, useState, useEffect } from "react";

import axios from "axios";
import { Link, useParams } from "react-router";
import urljoin from "url-join";

import WebPageTable from "@contents/web_pages/WebPageTable";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import ContentFrame from "@shared/ContentFrame";
import { renderCreateButton } from "@shared/FormUtils";
import PageHeader from "@shared/PageHeader";

const TemplateWebPages = () => {
  const { apiRoot } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const { id } = useParams();
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    const fetchTemplate = async () => {
      const response = await axios.get(urljoin(apiRoot, `/contents/web_page_templates/${id}`));
      setTemplate(response.data.web_page_template);
    };

    fetchTemplate();
  }, [apiRoot, id]);

  const renderAddWebPagesButton = () => {
    if (editEnabled()) {
      return (
        <Link to={`/content/web/pages/new?contents_web_page_template_id=${id}`}>
          {renderCreateButton("Add Web Page", () => { })}
        </Link>
      );
    }
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.static_files_edit) {
      return false;
    }
    return true;
  };

  const renderWebPagesTable = () => {
    return <WebPageTable fetchUrl={urljoin(apiRoot, `/contents/web_page_templates/${id}/web_pages`)} />;
  };

  return (
    <ContentFrame>
      <PageHeader text={`${template ? template.name : ""} Web Pages`} />
      {renderAddWebPagesButton()}
      <br />
      <br />
      {renderWebPagesTable()}
    </ContentFrame>
  );
};

export default TemplateWebPages;
