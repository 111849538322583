import React, { useContext, useState } from "react";

import { DataGrid } from "@mui/x-data-grid";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import { useFinalizeHousingBookings } from "@shared/hooks/useHousingBookings";
import { formatDateFriendly } from "@shared/TimeUtils";

const HousingBookingsFinalize = (props) => {
  const { bookings, fetched, goToIndex } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const [selectedIds, setSelectedIds] = useState([]);
  const finalizeHousingBookings = useFinalizeHousingBookings(apiRoot, event.id);

  const finalize = () => {
    finalizeHousingBookings.mutate(
      { ids: selectedIds },
      {
        onSuccess: (data) => {
          if (data.error === null) {
            alertSuccess("Bookings finalized successfully");
            goToIndex();
          } else {
            alertError(data.error);
          }
        },
        onError: (error) => {
          alertHttpError(error);
        }
      }
    );
  };

  /* eslint-disable react/prop-types, react/display-name */
  const columns = [
    {
      field: "guest_name",
      headerName: "Participant Name",
      flex: 1
    },
    {
      field: "event_participant_type",
      headerName: "Participant Type",
      valueGetter: (_value, row) => {
        return row.event_participant?.event_participant_type;
      },
      flex: 1
    },
    {
      flex: 1,
      field: "room_block_name",
      headerName: "Room Block"
    },
    {
      flex: 1,
      field: "hotel_name",
      headerName: "Hotel"
    },
    {
      flex: 1,
      field: "room_type_name",
      headerName: "Room Type"
    },
    {
      flex: 1,
      field: "check_in_date",
      headerName: "Check-in Date",
      renderCell: (params) => <span>{formatDateFriendly(params.value, null)}</span>
    },
    {
      flex: 1,
      field: "check_out_date",
      headerName: "Check-out Date",
      renderCell: (params) => <span>{formatDateFriendly(params.value, null)}</span>
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status"
    },
    {
      flex: 1,
      headerName: "Finalized?",
      field: "paid",
      searchable: false,
      renderCell: (params) => {
        if (params.value) {
          return <div style={{ textAlign: "left", paddingLeft: "8px" }}>✓</div>;
        }
        return <div style={{ textAlign: "left", paddingLeft: "8px" }} />;
      }
    }
  ];
  /* eslint-enable react/prop-types, react/display-name */

  const filteredBookings = () => {
    if (fetched && bookings) {
      let filtered = bookings.filter((b) => b.status === "booked");
      return filtered;
    }
    return [];
  };

  const renderFinalizeButton = () => {
    return renderCreateButton("Finalize Selected", finalize);
  };

  return (
    <div>
      <h2>Finalize Bookings</h2>
      <p>Select bookings to finalize</p>
      <div className="mb-4 mt-2">
        <DataGrid
          columns={columns}
          rows={filteredBookings()}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(ids) => setSelectedIds(ids)}
        />
      </div>
      {renderFinalizeButton()}
    </div>
  );
};

export default HousingBookingsFinalize;
