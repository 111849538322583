import React, { useMemo, useState } from "react";

import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Controller } from "react-hook-form";

import InputLabel from "@shared/forms/inputs/InputLabel";

const InputTime = (props) => {
  const { control, errors, label, optional = true, name } = props;
  const [fieldError, setFieldError] = useState(null);

  const errorMessage = useMemo(() => {
    switch (fieldError) {
      case "invalidDate":
      case "invalidTime": {
        return "Time is not valid";
      }

      default: {
        return "";
      }
    }
  }, [fieldError]);

  return (
    <div className="mb-4 w-full">
      <FormControl fullWidth>
        <FormLabel>
          <InputLabel label={label} optional={optional} />
        </FormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <TimePicker
              {...field}
              fullWidth
              onChange={(time) => {
                onChange(time);
              }}
              onError={(error) => {
                setFieldError(error);
              }}
              timezone="system"
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  helperText: errorMessage
                },
                actionBar: {
                  actions: ["clear"]
                }
              }}
              value={value}
            />
          )}
        />
        {errors && <FormHelperText>{errors}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default InputTime;
