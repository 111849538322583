import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import SessionsSetting from "@sessions/SessionsSetting";
import { useAddSessionTrack, useDeleteSessionTrack } from "@shared/hooks/useSessions";

const SessionTracks = (props) => {
  const { tracks } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const addSessionTrack = useAddSessionTrack(apiRoot, event.id);
  const deleteSessionTrack = useDeleteSessionTrack(apiRoot, event.id);

  return (
    <SessionsSetting
      addMutation={addSessionTrack}
      deleteMutation={deleteSessionTrack}
      collection={tracks}
      label="Session Tracks"
      name="track"
      description={
        <div>
          Tracks allow you to separate your sessions into large groups based on target audience. Examples: Software
          Developers, Sales Enablement etc.
          <br />
          Note: Sessions can only be assigned to one track.
        </div>
      }
      separator={false}
    />
  );
};

export default SessionTracks;
