import React, { useContext, useEffect, useState } from "react";

import { Box } from "@mui/material";
import { DataGridPro } from '@mui/x-data-grid-pro';
import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import Loading from "@shared/Loading";

import EventSpeakersModal from "./EventSpeakersModal";
import EventSpeakersView from "./EventSpeakersView";

const EventSpeakers = () => {
  const { apiRoot } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [speakers, setSpeakers] = useState([]);
  const [editSpeaker, setEditSpeaker] = useState(null);
  const [viewSpeaker, setViewSpeaker] = useState(null);
  const [search, setSearch] = useState("");
  const [fetched, setFetched] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);

  useEffect(() => {
    const fetchSpeakers = async () => {
      const result = await axios(urljoin(apiRoot, "/speakers"));
      setSpeakers(result.data);
      setFetched(true);
    };

    fetchSpeakers();
  }, []);

  /* eslint-disable react/prop-types, react/display-name */
  const columns = [
    {
      field: "photo_url",
      headerName: "Photo",
      rebnderCell: (params) => {
        if (params.value) {
          return (
            <div className="sg-mgmt-table-speakers-photo-cell">
              <img
                className="sg-mgmt-table-speakers-photo-img"
                src={params.value}
                alt="speaker"
              />
            </div>
          );
        }
        return (
          <div className="sg-mgmt-table-speakers-photo-cell">
            <img
              className="sg-mgmt-table-speakers-photo-img"
              src="/images/icons/person-placeholder.png"
              alt="speaker placeholder"
            />
          </div>
        );
      }
    },
    {
      headerName: "First Name",
      field: "name_first",
      flex: 1
    },
    {
      headerName: "Last Name",
      field: "name_last",
      flex: 1
    },
    {
      headerName: "Email",
      field: "email",
      flex: 1
    },
    {
      headerName: "Company",
      field: "company",
      flex: 1
    }
  ];
  /* eslint-enable react/prop-types, react/display-name */


  const openAddModal = () => {
    setAddModalVisible(true);
  };

  const closeAddModal = () => {
    setAddModalVisible(false);
  };

  const openEditModal = () => {
    setEditModalVisible(true);
  };

  const closeEditModal = () => {
    setEditModalVisible(false);
  };

  const openViewModal = () => {
    setViewModalVisible(true);
  };

  const closeViewModal = () => {
    setViewModalVisible(false);
  };

  const addSpeaker = ses => {
    setSpeakers([ses].concat(speakers));
  };

  const updateSpeaker = ses => {
    setSpeakers(speakers.map(s => (s.id === ses.id ? ses : s)));
  };

  const deleteSpeaker = ses => {
    setSpeakers(speakers.filter(s => s.id !== ses.id));
  };

  const resetAddModal = () => {
    closeAddModal();
  };

  const resetEditModal = () => {
    closeEditModal();
    // setEditSpeaker(null);
  };

  const resetViewModal = () => {
    closeViewModal();
    setViewSpeaker(null);
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.speakers_edit) {
      return false;
    }
    return true;
  };

  const deleteEnabled = () => {
    if (user.role === "basic" && !user.permission.speakers_delete) {
      return false;
    }
    return true;
  };

  const renderSpeakersTable = () => {
    if (fetched) {
      return (
        <Box display="flex" width="100%">
          <DataGridPro
            autoHeight
            headerFilters
            columns={columns}
            rows={speakers}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name_last', sort: 'asc' }],
              },
            }}
            sx={{
              width: "100%",
              overflow: "hidden"
            }}
          />
        </Box>
      );
    }
    return <Loading />;
  };

  return (
    <div className="sg-mgmt-content-frame">
      <div className="sg-mgmt-btn" onClick={openAddModal}>
        <img
          className="sg-mgmt-btn-icon"
          src="/images/icons/plus-white.png"
          alt="add icon"
        />
        Add Speaker
      </div>
      <div className="sg-mgmt-content sg-mgmt-content-full">
        <h1>Speaker Management</h1>
        {renderSpeakersTable()}
      </div>
      <EventSpeakersModal
        closeModal={closeAddModal}
        modalVisible={addModalVisible}
        resetModal={resetAddModal}
        updateFunc={addSpeaker}
      />
      <EventSpeakersModal
        closeModal={closeEditModal}
        modalVisible={editModalVisible}
        resetModal={resetEditModal}
        speaker={editSpeaker}
        updateFunc={updateSpeaker}
      />
      <EventSpeakersView
        closeModal={closeViewModal}
        modalVisible={viewModalVisible}
        resetModal={resetViewModal}
        speaker={viewSpeaker}
      />
    </div>
  );
};

export default EventSpeakers;
