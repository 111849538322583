import React from "react";

import { FormControl, FormLabel, FormHelperText, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

import InputLabel from "@shared/forms/inputs/InputLabel";
import MuiSelectClearable from "@shared/MuiSelectClearable";

const InputSelect = (props) => {
  const { control, defaultValue, errors, label, options = [], optional = true, name } = props;

  return (
    <div className="mb-4 w-full">
      <FormControl fullWidth>
        <FormLabel>
          <InputLabel label={label} optional={optional} />
        </FormLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              {...field}
              fullWidth
              onChange={onChange}
              value={value}
              variant="outlined"
              size="small"
              MenuProps={{ disableScrollLock: true }}
              endAdornment={<MuiSelectClearable value={value} onChange={onChange} />}
            >
              <MenuItem value={""}>(none)</MenuItem>
              {options.map((option) => (
                <MenuItem key={`option-${option.label}`} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors && <FormHelperText>{errors}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default InputSelect;
