export default {
  id: null,
  title: "",
  code: "",
  session_type: "",
  track: "",
  description: "",
  date_and_time: null,
  length_minutes: 30,
  on_demand: false,
  on_demand_end_date_time: null,
  enrollment: "closed",
  meeting_url: "",
  capacity: 0,
  attendees: [],
  speakers: [],
  session_participants: [],
  session_tags: [],
  allow_overlap: false,
  presentation_type: 0,
  session_room_id: null
};
