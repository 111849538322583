import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import { useSessionsSettings } from "@shared/hooks/useSessions";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import SessionTags from "./SessionTags";
import SessionTracks from "./SessionTracks";
import SessionTypes from "./SessionTypes";

const SessionsSettings = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const sessionsSettingsQuery = useSessionsSettings(apiRoot, event.id);

  if (sessionsSettingsQuery.isPending) {
    return (
      <div className="w-full">
        <Loading />;
      </div>
    );
  }

  if (sessionsSettingsQuery.isError) {
    return <div className="w-full">Error: {sessionsSettingsQuery.error.message}</div>;
  }

  return (
    <div>
      <PageHeader text="Session Settings" />
      <div className="max-w-screen-lg">
        <SessionTracks apiRoot={apiRoot} tracks={sessionsSettingsQuery.data.tracks} />
        <SessionTypes apiRoot={apiRoot} types={sessionsSettingsQuery.data.types} />
        <SessionTags apiRoot={apiRoot} tags={sessionsSettingsQuery.data.tags} />
      </div>
    </div>
  );
};

export default SessionsSettings;
