import React from "react";

import { Navigate } from "react-router";

import Communications from "@communications/Communications";
import CommunicationsEmailsApp from "@communications/emails/CommunicationsEmailsApp";
import CommunicationsEmailMailingListsApp from "@communications/mailing_lists/CommunicationsEmailMailingListsApp";
import CommunicationsEmailSettings from "@communications/settings/CommunicationsEmailSettings";
import CommunicationsEmailTemplatesApp from "@communications/templates/CommunicationsEmailTemplatesApp";
import StaticFiles from "@contents/static_files/StaticFiles";
import TemplateEdit from "@contents/web_pages/templates/TemplateEdit";
import TemplateIndex from "@contents/web_pages/templates/TemplateIndex";
import TemplateWebPages from "@contents/web_pages/templates/TemplateWebPages";
import WebPageIndex from "@contents/web_pages/WebPageIndex";
import WebPages from "@contents/web_pages/WebPages";
import WebPagesEdit from "@contents/web_pages/WebPagesEdit";
import WebPageTags from "@contents/web_pages/WebPageTags";
import DashboardWrapper from "@dashboard/DashboardWrapper";
import EventApis from "@event/apis/EventApis";
import Caches from "@event/caches/Caches";
import EventDetailsRegistration from "@event/details/registration/EventDetailsRegistration";
import EventEdit from "@event/edit/EventEdit";
import Event from "@event/Event";
import EventMeetings1On1 from "@event/meetings1on1/EventMeetings1On1";
import EventMetadata from "@event/metadata/EventMetadata";
import Metadata from "@event/metadata/Metadata";
import EventSpeakers from "@event/speakers/EventSpeakers";
import EventSurveys from "@event/surveys/EventSurveys";
import EventUsers from "@event/users/EventUsers";
import FeatureFlags from "@feature_flags/FeatureFlags";
import Forms from "@forms/Forms";
import FormsEdit from "@forms/FormsEdit";
import FormsReports from "@forms/FormsReports";
import HousingBookings from "@housing/bookings/HousingBookings";
import HousingConfiguration from "@housing/configuration/HousingConfiguration";
import HousingHotels from "@housing/hotels/HousingHotels";
import Housing from "@housing/Housing";
import HousingOverview from "@housing/overview/HousingOverview";
import HousingReports from "@housing/reports/HousingReports";
import HousingRoomBlocks from "@housing/room_blocks/HousingRoomBlocks";
import PeopleEventParticipantsImport from "@people/participants/import/PeopleEventParticipantsImport";
import PeopleEventParticipantIndex from "@people/participants/PeopleEventParticipantIndex";
import PeopleEventParticipants from "@people/participants/PeopleEventParticipants";
import PeopleEventParticipantSettings from "@people/participants/PeopleEventParticipantSettings";
import PeopleEventParticipantTags from "@people/participants/PeopleEventParticipantTags";
import CustomReports from "@reporting/custom/CustomReports";
import Reporting from "@reporting/Reporting";
import StandardReports from "@reporting/standard/StandardReports";
import Session from "@sessions/session/Session";
import SessionAttendees from "@sessions/session/SessionAttendees";
import SessionDetails from "@sessions/session/SessionDetails";
import SessionNew from "@sessions/session/SessionNew";
import SessionNewDetails from "@sessions/session/SessionNewDetails";
import SessionResources from "@sessions/session/SessionResources";
import SessionSpeakers from "@sessions/session/SessionSpeakers";
import SessionVideos from "@sessions/session/SessionVideos";
import Sessions from "@sessions/Sessions";
import SessionsOverview from "@sessions/SessionsOverview";
import SessionsSettings from "@sessions/SessionsSettings";
import SessionsVenues from "@sessions/venues/SessionsVenues";
import TicketingPackages from "@ticketing/packages/TicketingPackages";
import TicketRequests from "@ticketing/ticket_requests/TicketRequests";
import Ticketing from "@ticketing/Ticketing";
import TicketingPackageAssignment from "@ticketing/TicketingPackageAssignment";
import TicketingTicketAssignment from "@ticketing/TicketingTicketAssignment";
import TicketingTicketEditSettings from "@ticketing/tickets/TicketingTicketEditSettings";
import TicketingTickets from "@ticketing/tickets/TicketingTickets";

export const ROUTES = [
  {
    path: "/",
    element: <Event />,
    children: [
      { index: true, element: <DashboardWrapper /> },
      { path: "apis", element: <EventApis /> },
      { path: "cache", element: <Caches /> },
      {
        path: "communications",
        element: <Communications />,
        children: [
          { index: true, element: <CommunicationsEmailsApp /> },
          { path: "templates", element: <CommunicationsEmailTemplatesApp /> },
          { path: "mailing_lists", element: <CommunicationsEmailMailingListsApp /> },
          { path: "settings", element: <CommunicationsEmailSettings /> }
        ]
      },
      { path: "content", element: <Navigate to="/content/static_files" replace /> },
      { path: "content/static_files", element: <StaticFiles /> },
      {
        path: "content/web",
        element: <WebPages />,
        children: [
          { index: true, element: <Navigate to="/content/web/pages" replace /> },
          { path: "pages", element: <WebPageIndex /> },
          { path: "templates", element: <TemplateIndex /> },
          { path: "settings", element: <WebPageTags /> }
        ]
      },
      { path: "content/web/pages/:id", element: <WebPagesEdit /> },
      { path: "content/web/templates/:id", element: <TemplateEdit /> },
      { path: "content/web/templates/:id/web_pages", element: <TemplateWebPages /> },

      { path: "edit", element: <EventEdit /> },
      { path: "feature_flags", element: <FeatureFlags /> },
      { path: "forms", element: <Forms /> },
      { path: "forms/:form_type", element: <Forms /> },
      { path: "forms/:form_type/:id/view", element: <FormsReports /> },
      { path: "forms/:form_type/:id", element: <FormsEdit /> },
      {
        path: "housing",
        element: <Housing />,
        children: [
          { index: true, element: <HousingOverview /> },
          { path: "bookings", element: <HousingBookings /> },
          { path: "settings", element: <HousingConfiguration /> },
          { path: "hotels", element: <HousingHotels /> },
          { path: "reports", element: <HousingReports /> },
          { path: "room_blocks", element: <HousingRoomBlocks /> }
        ]
      },
      { path: "meetings", element: <EventMeetings1On1 /> },
      {
        path: "custom_fields",
        element: <EventMetadata />,
        children: [
          { index: true, element: <Metadata /> },
          { path: "registration", element: <EventDetailsRegistration /> }
        ]
      },
      {
        path: "participants",
        element: <PeopleEventParticipants />,
        children: [
          { index: true, element: <PeopleEventParticipantIndex /> },
          { path: "import", element: <PeopleEventParticipantsImport /> },
          { path: "settings", element: <PeopleEventParticipantSettings /> },
          { path: "tags", element: <PeopleEventParticipantTags /> }
        ]
      },
      {
        path: "reporting",
        element: <Reporting />,
        children: [
          { index: true, element: <StandardReports /> },
          { path: "custom", element: <CustomReports /> }
        ]
      },
      {
        path: "sessions",
        element: <Sessions />,
        children: [
          { index: true, element: <SessionsOverview /> },
          { path: "venues", element: <SessionsVenues /> },
          { path: "settings", element: <SessionsSettings /> }
        ]
      },
      { path: "sessions/new", element: <SessionNew />, children: [{ index: true, element: <SessionNewDetails /> }] },
      {
        path: "sessions/:id",
        element: <Session />,
        children: [
          { index: true, element: <SessionDetails /> },
          { path: "attendees", element: <SessionAttendees /> },
          { path: "resources", element: <SessionResources /> },
          { path: "speakers", element: <SessionSpeakers /> },
          { path: "videos", element: <SessionVideos /> }
        ]
      },
      {
        path: "ticketing",
        element: <Ticketing />,
        children: [
          { index: true, element: <Navigate to="/ticketing/tickets" replace /> },
          { path: "packages", element: <TicketingPackages /> },
          { path: "tickets", element: <TicketingTickets /> },
          { path: "requests", element: <TicketRequests /> },
          { path: "settings", element: <TicketingTicketEditSettings /> }
        ]
      },
      { path: "ticketing/tickets/:id", element: <TicketingTicketAssignment /> },
      { path: "ticketing/packages/:id", element: <TicketingPackageAssignment /> },
      { path: "speakers", element: <EventSpeakers /> },
      { path: "surveys", element: <EventSurveys /> },
      { path: "users", element: <EventUsers /> }
    ]
  }
];
