import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import SessionContext from "@sessions/session/SessionContext";
import { alertSuccess, alertError } from "@shared/Alerts";
import GrowlTable from "@shared/GrowlTable";
import { useAddSessionAssignment, useRemoveSessionAssignment } from "@shared/hooks/useSessions";

const SessionAssignmentTable = (props) => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { session } = useContext(SessionContext);
  const { items, mode, role } = props;
  const addAssignment = useAddSessionAssignment(apiRoot, event.id, session.id);
  const removeAssignment = useRemoveSessionAssignment(apiRoot, event.id, session.id);

  const assign = (gid) => {
    addAssignment.mutate(
      { data: { event_participant_gid: gid, session_participant: { role: role } } },
      {
        onSuccess: (data) => {
          if (data.error === null) {
            alertSuccess(`Added ${role} to session`);
          } else {
            alertError(`Failed assigning ${role} to session`);
          }
        }
      }
    );
  };

  const unassign = (gid) => {
    removeAssignment.mutate(
      { data: { event_participant_gid: gid } },
      {
        onSuccess: (data) => {
          if (data.error === null) {
            alertSuccess(`Removed ${role} from session`);
          } else {
            alertError(`Failed removing ${role} from session`);
          }
        }
      }
    );
  };

  const filteredItems = () => {
    const assignedIds = session.session_participants
      .filter((sp) => sp.role === role)
      .map((sp) => sp.event_participant.gid);
    if (mode === "available") {
      return items.filter((att) => !assignedIds.includes(att.gid));
    }
    return items.filter((att) => assignedIds.includes(att.gid));
  };

  const renderAddAssign = (item) => {
    if (mode !== "available") {
      return <></>;
    }

    return (
      <>
        <span className="cursor-pointer" onClick={() => assign(item.gid)}>
          Assign
        </span>
      </>
    );
  };

  const renderRemoveAssign = (item) => {
    if (mode === "available") {
      return <></>;
    }

    return (
      <>
        <span className="cursor-pointer" onClick={() => unassign(item.gid)}>
          Remove
        </span>
      </>
    );
  };

  const tableColumns = [
    {
      headerName: "GID",
      field: "gid",
      flex: 1
    },
    {
      headerName: "First Name",
      field: "name_first",
      flex: 1
    },
    {
      headerName: "Last Name",
      field: "name_last",
      flex: 1
    },
    {
      headerName: "Email",
      field: "email",
      flex: 1
    },
    {
      headerName: "Company",
      field: "company",
      flex: 1
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      getActions: (params) => [renderAddAssign(params.row), renderRemoveAssign(params.row)]
    }
  ];

  return <GrowlTable columns={tableColumns} items={filteredItems()} tableName={`${event.slug}-${role}-${mode}`} />;
};

export default SessionAssignmentTable;
