import React, { useContext, useState } from "react";

import { Button } from "@mui/material";
import axios from "axios";
import { parse, parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { useConfirm } from "material-ui-confirm";
import { Link } from "react-router";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import SessionStatus from "@sessions/session/SessionStatus";
import SessionModalView from "@sessions/SessionModalView";
import SessionsContext from "@sessions/SessionsContext";
import SessionsSettingsContext from "@sessions/SessionsSettingsContext";
import SessionsTable from "@sessions/SessionsTable";
import { alertError, alertSuccess } from "@shared/Alerts";
import { useCloneSession, useDeleteSession } from "@shared/hooks/useSessions";
import PageHeader from "@shared/PageHeader";

const SessionsOverview = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const { sessions } = useContext(SessionsContext);
  const { tracks, types, venues } = useContext(SessionsSettingsContext);
  const confirm = useConfirm();
  const cloneSession = useCloneSession(apiRoot, event.id);
  const deleteSession = useDeleteSession(apiRoot, event.id);

  const [modalViewVisible, setModalViewVisible] = useState(false);
  const [viewSessionId, setViewSessionId] = useState(null);

  const timeParse = (time) => {
    return parse(time, "h:mm a", new Date());
  };

  const sessionDateRange = sessions
    .filter((s) => s.date_and_time_local)
    .map((s) => s.date_and_time_local.split("T")[0]);
  const dateRange = [...new Set([...sessionDateRange])].toSorted();

  const tableColumns = (onDemand = false) =>
    [
      {
        headerName: "Title",
        field: "title",
        flex: 1
      },
      onDemand
        ? null
        : {
            headerName: "Start Time",
            field: "start_time",
            flex: 1,
            valueGetter: (_value, row) => timeParse(row.time_formatted).getTime(),
            renderCell: (params) => params.row.time_formatted
          },
      {
        headerName: "Session Track",
        field: "session_track_id",
        type: "singleSelect",
        valueOptions: [...new Set(tracks.map((t) => t.name).filter(Boolean))],
        flex: 1
      },
      {
        headerName: "Session Type",
        field: "session_type_name",
        type: "singleSelect",
        valueOptions: [...new Set(types.map((t) => t.name).filter(Boolean))],
        flex: 1
      },
      {
        headerName: "Room",
        field: "room_name",
        type: "singleSelect",
        valueOptions: [...new Set(sessions.map((s) => s.room_name).filter(Boolean))],
        flex: 1
      },
      {
        headerName: "Code",
        field: "code",
        flex: 1
      },
      {
        headerName: "Speakers",
        field: "speaker_count",
        flex: 1
      },
      {
        headerName: "Attendees",
        field: "attendee_count",
        flex: 1
      },
      {
        headerName: "Status",
        headerAlign: "center",
        field: "published",
        align: "center",
        valueGetter: (_value, row) => (row.published ? "Published" : "Draft"),
        renderCell: (params) => <SessionStatus session={params.row} />
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 200,
        flex: 1,
        getActions: (params) => [
          renderViewAction(params.row),
          renderEditAction(params.row),
          renderDeleteAction(params.row),
          renderCloneAction(params.row)
        ]
      }
    ].filter((ele) => !!ele);

  const performClone = (session) => {
    const callbacks = {
      onSuccess: (data) => {
        if (data.error === null) {
          alertSuccess("Session cloned");
        } else {
          alertError(`Unable to clone session: ${data.error}`);
        }
      },
      onError: (error) => {
        if (error?.response?.data?.error) {
          alertError(error.response?.data?.error);
        } else {
          alertError(error);
        }
      }
    };

    cloneSession.mutate({ id: session.id }, callbacks);
  };

  const performDelete = (session) => {
    const callbacks = {
      onSuccess: (data) => {
        if (data.error === null) {
          alertSuccess("Session deleted");
        } else {
          alertError(`Unable to delete session: ${data.error}`);
        }
      },
      onError: (error) => {
        if (error?.response?.data?.error) {
          alertError(error.response?.data?.error);
        } else {
          alertError(error);
        }
      }
    };

    deleteSession.mutate({ id: session.id }, callbacks);
  };

  const renderViewAction = (session) => (
    <>
      <span
        className="cursor-pointer"
        onClick={() => {
          modalViewOpen(session.id);
        }}
      >
        View
      </span>
    </>
  );

  const renderEditAction = (session) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <Link className="cursor-pointer" to={`/sessions/${session.id}`}>
          Edit
        </Link>
      </>
    );
  };

  const renderDeleteAction = (session) => {
    if (!deleteEnabled()) {
      return <></>;
    }

    return (
      <>
        <div
          className="cursor-pointer"
          onClick={() => {
            confirm({
              title: "Confirm delete",
              description: "Are you sure you want to delete this session?"
            })
              .then(() => {
                performDelete(session);
              })
              .catch((err) => {
                alertError(err);
              });
          }}
        >
          Delete
        </div>
      </>
    );
  };

  const renderCloneAction = (session) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <div
          className="cursor-pointer"
          onClick={() => {
            confirm({
              title: "Confirm clone",
              description: "Are you sure you want to clone this session?"
            })
              .then(() => {
                performClone(session);
              })
              .catch((err) => {
                alertError(err);
              });
          }}
        >
          Clone
        </div>
      </>
    );
  };

  const modalViewOpen = (id) => {
    setViewSessionId(id);
    setModalViewVisible(true);
  };

  const modalViewReset = () => {
    setModalViewVisible(false);
    setViewSessionId(null);
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.sessions_edit) {
      return false;
    }
    return true;
  };

  const deleteEnabled = () => {
    if (user.role === "basic" && !user.permission.sessions_delete) {
      return false;
    }
    return true;
  };

  const filterSessionsByDate = (date) => {
    return sessions.filter((session) => {
      // exclude on-demand sessions from date tables
      if (session.on_demand) {
        return false;
      }

      // HACK: filter out sessions without date/time
      // These should be on-demand sessions already filtered out,
      // but this is a contingency to catch possible UI breakage.
      if (!session.date_and_time_local) {
        console.log(`Scheduled session without a date/time: ${session.gid} (${session.id})`);
        return false;
      }

      const sessionDate = session.date_and_time_local.split("T")[0];
      return sessionDate === date;
    });
  };

  const renderTable = (date, dateObj) => {
    const filteredSessions = filterSessionsByDate(date);
    if (filteredSessions.length === 0) {
      return <></>;
    }
    return (
      <SessionsTable
        columns={tableColumns()}
        key={date}
        keyBase={`${format(dateObj, "y-M-d")}-sessions`}
        items={filteredSessions}
        sortField="start_time"
        sortDirection="asc"
        title={format(dateObj, "EEEE, MMMM do, y")}
      />
    );
  };

  const renderOnDemandTable = () => {
    const onDemandSessions = sessions.filter((session) => session.on_demand);
    if (onDemandSessions.length === 0) {
      return <></>;
    }
    return (
      <SessionsTable
        columns={tableColumns(true)}
        key={"ondemand"}
        keyBase={"ondemand-sessions"}
        items={onDemandSessions}
        sortField="title"
        sortDirection="asc"
        title={"On-Demand Sessions"}
      />
    );
  };

  const renderNoSessionsMessage = () => {
    if (sessions.length === 0) {
      return (
        <p>
          <em>No sessions created yet.</em>
        </p>
      );
    }
    return <></>;
  };

  return (
    <div>
      <PageHeader text="Session Overview" />
      <div className="mt-2 mb-4">Add, edit and manage sessions from this panel.</div>
      <div>
        {editEnabled() && (
          <Link className="cursor-pointer" to={`/sessions/new`}>
            <Button variant={"contained"} type={"button"} color={"secondary"}>
              Create Session
            </Button>
          </Link>
        )}
      </div>
      <div className="sg-mgmt-event-sessions-wrapper">
        {dateRange.map((date) => {
          const dateObj = parseISO(date);
          return renderTable(date, dateObj);
        })}
        {renderOnDemandTable()}
        {renderNoSessionsMessage()}
      </div>
      <SessionModalView
        modalVisible={modalViewVisible}
        resetModal={modalViewReset}
        sessionId={viewSessionId}
        venues={venues}
      />
    </div>
  );
};

export default SessionsOverview;
