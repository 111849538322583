import React from "react";

import { TextField, FormControl, FormHelperText, FormLabel } from "@mui/material";
import { Controller } from "react-hook-form";

import InputLabel from "@shared/forms/inputs/InputLabel";

const InputTextField = (props) => {
  const { control, errors, label, optional = true, name, placeholder = "" } = props;

  return (
    <div className="mb-4 w-full">
      <FormControl fullWidth>
        <FormLabel>
          <InputLabel label={label} optional={optional} />
        </FormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <>
              <TextField
                {...field}
                fullWidth
                onChange={onChange}
                placeholder={placeholder}
                size="small"
                value={value}
                variant="outlined"
              />
            </>
          )}
        />
        {errors && <FormHelperText>{errors}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default InputTextField;
