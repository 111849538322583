import React, { useContext } from "react";

import SessionContext from "@sessions/session/SessionContext";
import SessionStatus from "@sessions/session/SessionStatus";
import SessionTitle from "@sessions/session/SessionTitle";

const SessionTitleAndStatus = () => {
  const { session } = useContext(SessionContext);
  return (
    <div className="mb-4">
      <SessionTitle />
      <div className="mt-1">Status: <SessionStatus session={session} /></div>
    </div>
  );
};

export default SessionTitleAndStatus;
