import React, { useContext } from "react";

import { useParams, useNavigate } from "react-router";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import SessionContext from "@sessions/session/SessionContext";
import SessionsSettingsContext from "@sessions/SessionsSettingsContext";
import { useSession, useSessionsSettings } from "@shared/hooks/useSessions";
import Loading from "@shared/Loading";
import RouterTabbedPage from "@shared/RouterTabbedPage";

const Session = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const sessionsSettingsQuery = useSessionsSettings(apiRoot, event.id);
  const sessionQuery = useSession(apiRoot, event.id, id);

  const tabs = [
    { label: "Session Details", path: `/sessions/${id}` },
    { label: "Speakers", path: `/sessions/${id}/speakers` },
    { label: "Attendees", path: `/sessions/${id}/attendees` },
    { label: "Resources", path: `/sessions/${id}/resources` },
    { label: "Videos", path: `/sessions/${id}/videos` }
  ];

  if (user && user.role == "basic" && !user.permission.sessions_edit) {
    navigate("/sessions");
  }

  if (
    sessionQuery.isLoading ||
    sessionQuery.isPending ||
    sessionsSettingsQuery.isLoading ||
    sessionsSettingsQuery.isPending
  ) {
    return (
      <div className="w-full">
        <Loading />;
      </div>
    );
  }

  if (sessionQuery.isError) {
    return <div className="w-full">Error: {sessionQuery.error.message}</div>;
  }

  if (sessionsSettingsQuery.isError) {
    return <div className="w-full">Error: {sessionsSettingsQuery.error.message}</div>;
  }

  return (
    <SessionsSettingsContext.Provider
      value={{
        config: sessionsSettingsQuery.data.config,
        tags: sessionsSettingsQuery.data.tags,
        tracks: sessionsSettingsQuery.data.tracks,
        types: sessionsSettingsQuery.data.types,
        venues: sessionsSettingsQuery.data.venues
      }}
    >
      <SessionContext.Provider
        value={{
          session: sessionQuery.data.session
        }}
      >
        <RouterTabbedPage tabs={tabs} />;
      </SessionContext.Provider>
    </SessionsSettingsContext.Provider>
  );
};

export default Session;
