import React, { useContext } from "react";

import SessionForm from "@sessions/session/SessionForm";
import SessionTitleAndStatus from "@sessions/session/SessionTitleAndStatus";
import SessionsSettingsContext from "@sessions/SessionsSettingsContext";

const SessionNewDetails = () => {
  const { config, tags, tracks, types, venues } = useContext(SessionsSettingsContext);
  const session = {};

  return (
    <div className="w-full">
      <SessionTitleAndStatus />
      <div className="my-2">
        {
          "Edit and manage your session content here. Publish the session if it is ready for use, or save it as a draft to come back later. Assign speakers after you’ve saved the new session."
        }
      </div>
      <SessionForm config={config} session={session} tags={tags} tracks={tracks} types={types} venues={venues} />
    </div>
  );
};

export default SessionNewDetails;
