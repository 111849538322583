import React, { useContext, useEffect, useState } from "react";

import { Stack } from "@mui/material";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import { contentTypes } from "@contents/ContentTypes";
import EventContext from "@event/EventContext";
import { alertHttpError, alertError, alertSuccess } from "@shared/Alerts";
import FormikObserver from "@shared/FormikObserver";
import { renderCancelButton, renderSubmitButton, renderTextField, renderSelectField } from "@shared/FormUtils";
import Loading from "@shared/Loading";

const TemplateQuestionForm = (props) => {
  const { id, sortOrder, cancel, template, callbackSuccess, callbackFailure } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [dirty, setDirty] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [question, setQuestion] = useState(null);

  useEffect(() => {
    setQuestion(null);
    const fetchQuestion = async () => {
      const result = await axios(urljoin(apiRoot, `/contents/web_page_template_questions/${id ? id : "new"}`));
      setQuestion(result.data.web_page_template_question);
    };
    if (id) {
      fetchQuestion();
    }
  }, [apiRoot, id]);

  const isEditing = () => {
    return id;
  };

  const formConfig = {
    formUrl: isEditing()
      ? urljoin(apiRoot, `/contents/web_page_template_questions/${id}`)
      : urljoin(apiRoot, "/contents/web_page_template_questions"),
    method: isEditing() ? "PATCH" : "POST"
  };

  const submitForm = (values, { setSubmitting }) => {
    if (submitting) {
      return;
    }
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    setSubmitting(true);
    axios({
      url: formConfig.formUrl,
      method: formConfig.method,
      data: values
    })
      .then((response) => {
        if (!response.data.error) {
          callbackSuccess ? callbackSuccess(response) : () => { };
          alertSuccess("Web Page Template Question Saved");
          setSubmitting(false);
          setDirty(false);
        } else {
          callbackFailure ? callbackFailure(response) : () => { };
          alertError(`Unable to save web page: ${response.data.error}`);
          setSubmitting(false);
        }
      })
      .catch((error) => {
        console.log({
          error: error
        });
        setSubmitting(false);
        alertHttpError(error);
      });
  };

  const formOnChange = (formikProps) => {
    if (formikProps.dirty) {
      setDirty(true);
    }
  };

  const renderButtons = (formikProps) => {
    return (
      <Stack direction="row" spacing={2}>
        {renderSubmitButton("Save", submitting, {})}
        {renderCancelButton("Cancel", cancel)}
      </Stack>
    );
  };

  const getInitialTemplateValues = () => {
    const initialValues = {
      web_page_template_question: {
        contents_web_page_template_id: isEditing() ? question.contents_web_page_template_id : template.id,
        name: isEditing() ? question.name : "",
        content_type: isEditing() ? question.content_type : "",
        slug: isEditing() ? question.slug : "",
        config: {}
      }
    };
    //Used to force value
    if (sortOrder) {
      initialValues.web_page_template_question.sort_order = sortOrder;
    }
    return initialValues;
  };

  const renderFormFields = (formikProps) => {
    const { values } = formikProps;
    return (
      <div className="sg-mgmt-form-container">
        <div className="sg-mgmt-form-section">
          <div className="flex">
            <div className="mr-4 w-full">
              {renderTextField("Name", "web_page_template_question[name]", [], false, true)}
            </div>
          </div>
          <div className="flex">
            <div className="mr-4 w-full">
              {renderSelectField(
                "Content Type",
                "web_page_template_question[content_type]",
                contentTypes
                  .filter((x) => !x.disable_on_templates)
                  .map((x) => {
                    return {
                      value: x.slug,
                      label: x.name
                    };
                  }),
                [],
                [],
                false,
                {}
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (id && !question) {
    return <Loading />;
  }
  return (
    <Formik enableReinitialize initialValues={getInitialTemplateValues()} onSubmit={submitForm}>
      {(formikProps) => (
        <Form className="sg-mgmt-form" id={formConfig.formId}>
          <FormikObserver formikProps={formikProps} onChange={formOnChange} />
          {renderFormFields(formikProps)}
          {renderButtons(formikProps)}
        </Form>
      )}
    </Formik>
  );
};

export default TemplateQuestionForm;

