import React from "react";

const SessionContext = React.createContext({
  config: null,
  session: null,
  tags: null,
  venues: []
});

export default SessionContext;
