import React from "react";

import { FormControl, FormHelperText, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { Controller } from "react-hook-form";

import InputLabel from "@shared/forms/inputs/InputLabel";

const InputRadioGroup = (props) => {
  const { control, errors, label, options = [], optional = true, name, row = false } = props;

  return (
    <div className="mb-4 w-full">
      <FormControl fullWidth>
        <FormLabel>
          <InputLabel label={label} optional={optional} />
        </FormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <RadioGroup {...field} onChange={onChange} value={value} row={row}>
              {options.map((option) => (
                <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
              ))}
            </RadioGroup>
          )}
        />
        {errors && <FormHelperText>{errors}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default InputRadioGroup;
