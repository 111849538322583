import React, { useContext } from "react";

import { useConfirm } from "material-ui-confirm";

import WidgetContentFrame from "@dashboard/WidgetContentFrame";
import WidgetContext from "@dashboard/WidgetContext";
import WidgetHeader from "@dashboard/WidgetHeader";
import WidgetSettings from "@dashboard/WidgetSettings";
import EventContext from "@event/EventContext";
import { alertError, alertSuccess } from "@shared/Alerts";
import { renderButton, renderCancelButton, renderDeleteButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import {
  useDeleteTicketRequest,
  useTicketRequests,
  useTicketRequestApprove,
  useTicketRequestDeny,
  useUpdateTicketRequestCount
} from "@shared/hooks/useTicketRequests";
import Loading from "@shared/Loading";
import { formatTimeUtcToZone } from "@shared/TimeUtils";

import TicketRequestWidgetSettings from "./TicketRequestWidgetSettings";

const TicketRequestWidget = (props) => {
  const { editMode, widget } = useContext(WidgetContext);
  const { apiRoot, event } = useContext(EventContext).values;
  const { fullPage } = props;
  const confirm = useConfirm();
  const ticketRequests = useTicketRequests(apiRoot, event.id);
  const approveRequest = useTicketRequestApprove(apiRoot, event.id);
  const denyRequest = useTicketRequestDeny(apiRoot, event.id);
  const deleteTicketRequest = useDeleteTicketRequest(apiRoot, event.id);
  const updateRequestCount = useUpdateTicketRequestCount(apiRoot, event.id);

  const getRequestType = (row) => {
    if (row.ticket_type !== null) return "ticket";
    if (row.package_type !== null) return "package";
    return "";
  };

  const renderTicketName = (row) => {
    switch (getRequestType(row)) {
      case "ticket":
        return row.ticket_type.name;
      case "package":
        return row.package_type.name;
      default:
        return "";
    }
  };

  const saveCell = (params) => {
    if (params.row.approved !== null) return;

    const formData = new FormData();
    formData.set("gid", params.row.gid);
    formData.set("count", params.value);

    const callbacks = {
      onSuccess: (data) => {
        if (data.error === null) {
          alertSuccess("Ticket request updated");
        } else {
          alertError(data.error);
        }
      },
      onError: (error) => {
        alertError(error);
      }
    };

    updateRequestCount.mutate({ id: params.row.id, data: formData }, callbacks);
  };

  const ticketRequestUpdate = (approved, row, type) => {
    const formData = new FormData();
    formData.set("type", type);
    formData.set("gid", row.gid);
    formData.set("count", row.count);

    const callbacks = {
      onSuccess: (data) => {
        if (data.error === null) {
          alertSuccess(`Ticket request ${approved ? "approved" : "denied"}`);
        } else {
          alertError(data.error);
        }
      },
      onError: (error) => {
        alertError(error);
      }
    };

    if (approved) {
      approveRequest.mutate({ id: row.id, data: formData }, callbacks);
    } else {
      denyRequest.mutate({ id: row.id, data: formData }, callbacks);
    }
  };

  const ticketRequestDelete = (row) => {
    const callbacks = {
      onSuccess: (data) => {
        if (data.error === null) {
          alertSuccess(`Ticket request deleted`);
        } else {
          alertError(data.error);
        }
      },
      onError: (error) => {
        alertError(error);
      }
    };
    deleteTicketRequest.mutate({ id: row.id }, callbacks);
  };

  const renderApproveButton = (row) => {
    switch (getRequestType(row)) {
      case "ticket":
        if (row.ticket_type.available_tickets >= row.count) {
          return renderButton("Approve", () => ticketRequestUpdate(true, row, "ticket"));
        }
        return renderButton("Approve", () => {}, { variant: "outlined", disabled: true });
      case "package":
        if (row.package_type.available_packages >= row.count) {
          return renderButton("Approve", () => ticketRequestUpdate(true, row, "package"));
        }
        return renderButton("Approve", () => {}, { variant: "outlined", disabled: true });
      default:
        return "";
    }
  };

  const renderDenyButton = (row) => {
    switch (getRequestType(row)) {
      case "ticket":
        return renderCancelButton("Deny", () => ticketRequestUpdate(false, row, "ticket"));
      case "package":
        return renderCancelButton("Approve", () => ticketRequestUpdate(false, row, "package"));
      default:
        return "";
    }
  };

  const attemptDelete = (row) => {
    confirm({
      title: "Confirm removal",
      description:
        "Are you sure you want to delete this ticket request? This cannot be undone. Deletion will not remove any previously assigned tickets."
    })
      .then(() => {
        ticketRequestDelete(row);
      })
      .catch((err) => {
        alertError(err);
      });
  };

  const renderDelete = (row) => {
    return renderDeleteButton("Delete", () => attemptDelete(row));
  };

  const renderActions = (row) => {
    if (row.approved === null) {
      return (
        <>
          <span className="mr-2">{renderApproveButton(row)}</span>
          <span className="mr-2">{renderDenyButton(row)}</span>
          <span>{renderDelete(row)}</span>
        </>
      );
    }
    return (
      <div className="flex h-full items-center leading-normal">
        <div>
          <span className="border-none text-black">{row.approved ? "approved" : "denied"}</span>
          <br />
          <span className="text-sg-comms-saved text-sm">
            {row.updated_by} |{" "}
            {row.approved
              ? formatTimeUtcToZone(row.approved_at, event.time_zone)
              : formatTimeUtcToZone(row.denied_at, event.time_zone)}
          </span>
        </div>
        <span className="ml-2">{renderDelete(row)}</span>
      </div>
    );
  };

  const columns = [
    {
      headerName: "Requested By",
      field: "requested_by",
      flex: 1,
      valueGetter: (_value, row) => row?.event_participant_requestor?.name_full,
      renderCell: (params) => <>{params.row.event_participant_requestor.name_full}</>
    },
    {
      headerName: "Time",
      field: "time",
      flex: 1,
      valueGetter: (_value, row) => {
        return row.created_at;
      },
      renderCell: (params) => (
        <span className="text-sm text-sg-saved">{formatTimeUtcToZone(params.row.created_at, event.time_zone)}</span>
      )
    },
    {
      headerName: "Ticket Name",
      field: "name",
      flex: 1,
      valueGetter: (_value, row) => renderTicketName(row),
      renderCell: (params) => <>{renderTicketName(params.row)}</>
    },
    {
      headerName: "Customer/Partner",
      field: "customer_partner",
      flex: 0,
      valueGetter: (_value, row) => row?.event_participant_customer?.name_full,
      renderCell: (params) => (
        <div>
          <span className="text-black">{params.row.event_participant_customer.name_full}</span>
          <br />
          <span className="text-sm text-sg-saved">{params.row.event_participant_customer.company}</span>
        </div>
      )
    },
    {
      headerName: "Count",
      field: "count",
      editable: true,
      type: "number",
      align: "left",
      headerAlign: "left",
      flex: 1,
      valueGetter: (_value, row) => {
        return row?.count;
      },
      valueSetter: (value, row) => {
        row.count = value;
        return row;
      }
    },
    {
      headerName: "Action",
      field: "action",
      minWidth: 360,
      sortable: false,
      flex: 1,
      renderCell: (params) => <>{renderActions(params.row)}</>
    }
  ];

  const ticketTableName = () => {
    if (widget.gid) {
      return `${widget.gid}-${event.id}-ticket-requests`;
    }

    return `${event.id}-ticket-requests`;
  };

  const renderContent = () => {
    if (ticketRequests.data.ticket_requests.length === 0) return <>No ticket requests available</>;
    return (
      <div>
        <GrowlTable
          columns={columns}
          items={ticketRequests.data.ticket_requests}
          onCellEditStop={saveCell}
          sortField="name"
          sortDirection="asc"
          tableName={ticketTableName()}
          pageSizeInitial={10}
          pageSizeOptions={[5, 10, 25]}
        />
      </div>
    );
  };

  const renderControls = () => {
    if (!editMode) return <></>;

    return <WidgetSettings label="Overview Settings" settingsComponent={TicketRequestWidgetSettings} />;
  };

  if (ticketRequests.isLoading) {
    return <Loading />;
  }

  if (ticketRequests.isError) {
    return <div>Error: {ticketRequests.error}</div>;
  }

  if (fullPage) {
    return (
      <>
        <WidgetHeader />
        <div className="pt-4">{renderContent()}</div>
        {renderControls()}
      </>
    );
  }
  return (
    <WidgetContentFrame fluidHeight>
      <WidgetHeader />
      <div className="pt-4">{renderContent()}</div>
      {renderControls()}
    </WidgetContentFrame>
  );
};

export default TicketRequestWidget;
