import React from "react";

import RequiredAsterisk from "@shared/forms/RequiredAsterisk";

const InputLabel = (props) => {
  const { optional, label } = props;

  const labelText = optional ? (
    label
  ) : (
    <>
      {label}
      <RequiredAsterisk />
    </>
  );

  return <>{labelText}</>;
};

export default InputLabel;
