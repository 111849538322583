import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import SessionAssignmentTable from "@sessions/session/SessionAssignmentTable";
import SessionTitleAndStatus from "@sessions/session/SessionTitleAndStatus";
import { useParticipantsTable } from "@shared/hooks/useParticipants";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

const SessionAssignment = (props) => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { role, roleLabel, description } = props;
  const participantsQuery = useParticipantsTable(apiRoot, event.id);

  if (participantsQuery.isPending) {
    return <Loading />;
  }

  if (participantsQuery.error) {
    console.log(participantsQuery.error);
    return <div>Error loading data. Please refresh and try again.</div>;
  }

  return (
    <div>
      <div className="max-w-screen-lg">
        <SessionTitleAndStatus />
        <div>{description}</div>
      </div>
      <div className="w-full my-4">
        <PageHeader text={`Assigned ${roleLabel}s`} subHeader />
        <SessionAssignmentTable
          items={participantsQuery.data.participants.filter((ele) => ele.role === role)}
          mode={"assigned"}
          role={role}
        />
        <PageHeader text={`Available ${roleLabel}s`} subHeader />
        <SessionAssignmentTable
          items={participantsQuery.data.participants.filter((ele) => ele.role === role)}
          mode={"available"}
          role={role}
        />
      </div>
    </div>
  );
};

export default SessionAssignment;
