import React, { useContext } from "react";

import SessionContext from "@sessions/session/SessionContext";
import PageHeader from "@shared/PageHeader";

const SessionTitle = () => {
  const { session } = useContext(SessionContext);

  if (session?.id) {
    return <PageHeader text={session.title} />;
  }

  return <PageHeader text="New Session" />;
};

export default SessionTitle;
