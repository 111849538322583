import React from "react";

import { Chip, Stack } from "@mui/material";
import clsx from "clsx";
import { useConfirm } from "material-ui-confirm";
import { useForm } from "react-hook-form";

import { alertError, alertSuccess } from "@shared/Alerts";
import InputTextField from "@shared/forms/inputs/InputTextField";
import { renderButton } from "@shared/FormUtils";

const SessionsSetting = (props) => {
  const { name, description, label, collection, addMutation, deleteMutation, separator = true } = props;
  const confirm = useConfirm();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    watch
  } = useForm({
    defaultValues: {
      name: ""
    }
  });

  const watchName = watch("name");

  const capitalize = (s) => s && String(s[0]).toUpperCase() + String(s).slice(1);

  const attemptDelete = (id) => {
    confirm({
      title: "Confirm removal",
      description: `Are you sure you want to remove this ${name}? This cannot be undone.`
    })
      .then(() => {
        deleteMutation.mutate(
          { id: id },
          {
            onSuccess: () => {
              alertSuccess(`${capitalize(name)} deleted`);
            },
            onError: (error) => {
              alertError(error.message);
            }
          }
        );
      })
      .catch((err) => {
        alertError(err);
      });
  };

  const renderAddButton = (isSubmitting, textboxFilled) => {
    return renderButton("Add", () => {}, {
      type: "submit",
      color: "secondary",
      disabled: textboxFilled || isSubmitting
    });
  };

  const renderCollection = () => (
    <div className="mt-2">
      <div className="mr-2 text-black">{label}:</div>
      <div className="my-2 flex flex-wrap gap-2">
        {collection.map((item) => (
          <div className="flex-none" key={item.id}>
            <Chip
              color="primary"
              label={item.name}
              onClick={() => console.log(`edit ${name} ${item.id}`)}
              onDelete={() => attemptDelete(item.id)}
            />
          </div>
        ))}
      </div>
    </div>
  );

  const submitAddFn = (data) => {
    addMutation.mutate(
      { data },
      {
        onSuccess: () => {
          alertSuccess(`${capitalize(name)} added`);
          reset();
        },
        onError: (error) => {
          alertError(error.message);
        }
      }
    );
  };

  return (
    <div className={clsx("max-w-screen-md", separator ? "mt-8 border-t border-bg-1 pt-8" : "no-separator")}>
      <h2 className="font-normal">{label}</h2>
      <div className="mb-4 mt-2">{description}</div>
      <form onSubmit={handleSubmit(submitAddFn)}>
        <Stack direction="row" spacing={2}>
          <div className="w-96">
            <InputTextField
              control={control}
              errors={errors.name}
              label=""
              name="name"
              placeholder={`Enter ${name} name`}
            />
          </div>
          <div className="sg-mgmt-form-input-container">{renderAddButton(isSubmitting, watchName?.length === 0)}</div>
        </Stack>
      </form>
      {renderCollection()}
    </div>
  );
};

export default SessionsSetting;
