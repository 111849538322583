import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import SessionsSetting from "@sessions/SessionsSetting";
import { useAddSessionType, useDeleteSessionType } from "@shared/hooks/useSessions";

const SessionTypes = (props) => {
  const { types } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const addSessionType = useAddSessionType(apiRoot, event.id);
  const deleteSessionType = useDeleteSessionType(apiRoot, event.id);

  return (
    <SessionsSetting
      addMutation={addSessionType}
      deleteMutation={deleteSessionType}
      collection={types}
      label="Session Types"
      name="type"
      description={
        <div>
          Types allow you to define what the session is. Examples: Breakout, Workshop, Meal, Activity etc.
          <br />
          Note: Sessions can only be assigned to one type.
        </div>
      }
    />
  );
};

export default SessionTypes;
