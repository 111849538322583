import React from "react";

import { FormControl, FormLabel, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";

import InputLabel from "@shared/forms/inputs/InputLabel";
import RichTextEditor from "@shared/forms/RichTextEditor";

const InputRichTextArea = (props) => {
  const { control, errors, label, optional = true, name } = props;

  return (
    <div className="mb-4 w-full">
      <FormControl fullWidth>
        <FormLabel>
          <InputLabel optional={optional} label={label} />
        </FormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => <RichTextEditor value={value} onChange={onChange} />}
        />
        {errors && <FormHelperText>{errors}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default InputRichTextArea;
