import React, { useContext } from "react";

import EventUserContext from "@event/EventUserContext";
import RouterTabbedPage from "@shared/RouterTabbedPage";
import { TabSettingsIcon } from "@shared/tabs/TabIcons";

const WebPages = () => {
  const { user } = useContext(EventUserContext);
  const getTabs = () => {
    let tabs = [
      {
        label: "Pages",
        path: "/content/web/pages"
      },
      {
        label: "Templates",
        path: "/content/web/templates"
      }
    ];

    if (user && (user.role != "basic" || user.permission.web_pages_edit)) {
      tabs.push({ label: "Settings", path: "/content/web/settings", icon: TabSettingsIcon });
    }

    return tabs;
  };

  return <RouterTabbedPage tabs={getTabs()} />;
};

export default WebPages;
